import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Tooltip } from "@material-ui/core";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import Configs from "../Service/Configuration";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Grid } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundImage: "linear-gradient(to right, #FC4E31, #EE9D4A)",
    paddingLeft: 30,
    paddingRight: 30,
    // background: "#2b979a"
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  link: {
    color: "#ffffff",
    textDecoration: "none",
    fontSize: 14,
    marginRight: 30,
  },
  homeLink: {
    color: "#ffffff",
    textDecoration: "none",
    fontSize: 14,
  },
}));

export default function Navigator(props) {
  const [pagePath, setPagePath] = useState(window.location.pathname);
  const [currentSideMenu, setCurrentSideMenu] = useState("");
  const location = useLocation();
  useEffect(() => {
    setPagePath(window.location.pathname);
  }, [location.pathname]);

  const classes = useStyles();
  const loggedIn = props.loggedIn;
  const user = props.user;
  // console.log(user);
  const bt = loggedIn ? "Sign out" : "Login";
  const history = useHistory();

  var siteMenuWidth = "15REM"
  if (currentSideMenu == "")
    siteMenuWidth = "15REM"
    else
    siteMenuWidth = "30REM"

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          {/* <Toolbar onClick={() => setPagePath(window.location.pathname)}> */}

          {pagePath === "/" ? (
            <Tooltip title="Menu">
              <IconButton
                edge="start"
                onClick={props.toggleDrawer}
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Link to="/" className={classes.homeLink}>
              <Tooltip title="Report Home">
                <IconButton
                  edge="start"
                  className= "torchHomeMenuIcon"
                  color="inherit"
                  aria-label="menu"
                >
                  <HomeOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Link>
          )}

          <Typography variant="h6" className={classes.title}>
            <Link to="/" className="torchNavigatorHeaderLink">
              DASHBOARD{" "}
            </Link>
            {parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin && (
              <Link to="/Release" className="torchNavigatorHeaderLink">
                RELEASE
              </Link>
            )}
            
            {((Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType != Configs.supportSiteType.SC4CSupport
                && (parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin
                || parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator))
            || ((Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.SC4CSupport
              && (parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin)))) && (
            <div style={{ flexGrow: 1, marginRight: 30 }} className="dropdownTorch">
              <Link 
                to="#"
                style={{ width: siteMenuWidth, background: 'transparent', fontSize: 14, }}
                className="dropbtnTorch"
              >              
                SITE SUPPORT
              </Link>
              <div
                style={{ height: '38REM', width: siteMenuWidth }}
                className="dropdownTorch-content"
              >
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <div style={{ height: '38REM', width: "15REM" }}>
                      <div
                          onMouseEnter={() => setCurrentSideMenu("newSite")}
                          onMouseLeave={() => setCurrentSideMenu("")}
                      >
                        {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                        <ListItem button  style={{pointerEvents: "none"}}>
                          <Link style={{width: "20REM", background: 'transparent', fontSize: 14}}>              
                            New site
                          </Link>
                        </ListItem>
                        )}
                      </div>
                      <div
                          onMouseEnter={() => setCurrentSideMenu("modifySite")}
                          onMouseLeave={() => setCurrentSideMenu("")}
                      >
                        {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                        <ListItem button  style={{pointerEvents: "none"}}>
                          <Link style={{width: "20REM", background: 'transparent', fontSize: 14}}>              
                            Modify site
                          </Link>
                        </ListItem>
                        )}
                      </div>
                      <div
                          onMouseEnter={() => setCurrentSideMenu("siteMonitoring")}
                          onMouseLeave={() => setCurrentSideMenu("")}
                      >
                        {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                        <ListItem button  style={{pointerEvents: "none"}}>
                          <Link style={{width: "20REM", background: 'transparent', fontSize: 14}}>              
                            Site monitoring
                          </Link>
                        </ListItem>
                        )}
                      </div>
                      <div
                          onMouseEnter={() => setCurrentSideMenu("accountManagement")}
                          onMouseLeave={() => setCurrentSideMenu("")}
                      >
                        {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                        <ListItem button  style={{pointerEvents: "none"}}>
                          <Link style={{width: "20REM", background: 'transparent', fontSize: 14}}>              
                            Account management
                          </Link>
                        </ListItem>
                        )}
                      </div>                      
                      <ListItem
                        button
                        style = {{width: "85%"}}
                      >
                        <Link 
                          onClick={() => history.push("/configCreateSiteGroupings")}
                          style={{ background: 'transparent', fontSize: 14, }}
                        >
                          Create site groupings
                        </Link>
                      </ListItem>
                      {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                      <ListItem
                        button
                        style = {{width: "85%"}}
                      >
                        <Link 
                          onClick={() => history.push("/configEventViewerTaskScheduler")}
                          style={{ background: 'transparent', fontSize: 14, }}
                        >              
                          EventViewer & TaskScheduler
                        </Link>
                      </ListItem>
                      )}
                      {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                      <ListItem
                        button
                        style = {{width: "85%"}}
                      >
                        <Link 
                          onClick={() => history.push("/configRestartServices")}
                          style={{ background: 'transparent', fontSize: 14, }}
                        >              
                          Restart Services
                        </Link>
                      </ListItem>
                      )}                      
                      {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                      <ListItem
                        button
                        style = {{width: "85%"}}
                      >
                        <Link 
                          onClick={() => history.push("/configSoftwareRegister")}
                          style={{ background: 'transparent', fontSize: 14, }}
                        >
                          Software Register
                        </Link>
                      </ListItem>
                      )}
                    </div>
                  </Grid>

                  {currentSideMenu == "newSite"?                
                  <Grid 
                    onMouseEnter={() => setCurrentSideMenu("newSite")}
                    onMouseLeave={() => setCurrentSideMenu("")}
                    item xs={6}
                  >
                    <ListItem button>
                      <Link 
                        onClick={() => {
                          history.push("/configSiteRegister");
                          setCurrentSideMenu("");
                        }}
                        style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                      >              
                        Site Register
                      </Link>
                    </ListItem>
                    <ListItem button>
                      <Link 
                        onClick={() => {
                          history.push("/configSiteType");
                          setCurrentSideMenu("");
                        }}
                        style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                      >
                        Site Type
                      </Link>
                    </ListItem>
                  </Grid> : <div></div>}
                  
                  {currentSideMenu == "modifySite"?                
                  <Grid 
                    onMouseEnter={() => setCurrentSideMenu("modifySite")}
                    onMouseLeave={() => setCurrentSideMenu("")}
                    item xs={6}
                  >
                    <ListItem button>
                      <Link 
                        onClick={() => {
                          history.push("/configSoftwareMapping");
                          setCurrentSideMenu("");
                        }}
                        style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                      >              
                        Software Mapping
                      </Link>
                    </ListItem>                    
                    {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                      <ListItem button>
                        <Link
                          onClick={() => {
                            history.push("/SiteMachineDeactivation");
                            setCurrentSideMenu("");
                          }}
                          style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                        >
                          Site Machine Deactivation
                        </Link>
                      </ListItem>
                    )}
                  </Grid> : <div></div>}
                  
                  {currentSideMenu == "siteMonitoring"?                
                  <Grid 
                    onMouseEnter={() => setCurrentSideMenu("siteMonitoring")}
                    onMouseLeave={() => setCurrentSideMenu("")}
                    item xs={6}
                  >
                    <ListItem button>
                      <Link 
                        onClick={() => {
                          history.push("/configNewMachines");
                          setCurrentSideMenu("");
                        }}
                        style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                      >              
                        New Machines
                      </Link>
                    </ListItem>
                    <ListItem button>
                      <Link 
                        onClick={() => {
                          history.push("/configMachineOnSite");
                          setCurrentSideMenu("");
                        }}
                        style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                      >
                        Machine on site
                      </Link>
                    </ListItem>
                  </Grid> : <div></div>}

                  {currentSideMenu == "accountManagement"?                
                  <Grid 
                    onMouseEnter={() => setCurrentSideMenu("accountManagement")}
                    onMouseLeave={() => setCurrentSideMenu("")}
                    item xs={6}
                  >
                    <ListItem button>
                      <Link 
                        onClick={() => {
                          history.push("/configCreateAccount");
                          setCurrentSideMenu("");
                        }}
                        style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                      >
                        Create Account
                      </Link>
                    </ListItem>
                    <ListItem button>
                      <Link 
                        onClick={() => {
                          history.push("/configResetPassword");
                          setCurrentSideMenu("");
                        }}
                        style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                      >
                        Reset Password
                      </Link>
                    </ListItem>
                    <ListItem button>
                      <Link 
                        onClick={() => {
                          history.push("/configSupportUserManagement");
                          setCurrentSideMenu("");
                        }}
                        style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                      >
                        FHT support user management
                      </Link>
                    </ListItem>
                    <ListItem button>
                      <Link 
                        onClick={() => {
                          history.push("/configAccounts");
                          setCurrentSideMenu("");
                        }}
                        style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                      >
                        Accounts
                      </Link>
                    </ListItem>
                  </Grid> : <div></div>}
                </Grid>
              </div>
            </div>
            )}

            {(((Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType != Configs.supportSiteType.SC4CSupport)
              && (parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin
              || parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator
              || parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandE))
            || ((Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.SC4CSupport
              && (parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin)))) && (
            <div style={{ flexGrow: 1, marginRight: 30 }} className="dropdownTorch">
              <Link 
                to="#"
                style={{ width: siteMenuWidth, background: 'transparent', fontSize: 14, }}
                className="dropbtnTorch"
              >              
                REMOTE MANAGEMENT
              </Link>
              <div
                style={{ height: '38REM', width: siteMenuWidth }}
                className="dropdownTorch-content"
              >
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <div style={{ height: '38REM', width: "15REM" }}>
                      {((Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchSupport
                        || Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchTestSupport)
                        && (parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin
                      || parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator)) && (
                        <div
                            onMouseEnter={() => setCurrentSideMenu("cohortManagement")}
                            onMouseLeave={() => setCurrentSideMenu("")}
                        >
                          <ListItem button  style={{pointerEvents: "none"}}>
                            <Link style={{width: "20REM", background: 'transparent', fontSize: 14}}>              
                              Cohort management
                            </Link>
                          </ListItem>
                        </div>
                      )}
                      {(Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchSupport
                        || Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchTestSupport)
                        && (parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin
                            || parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator) && (
                        <div
                            onMouseEnter={() => setCurrentSideMenu("userManagement")}
                            onMouseLeave={() => setCurrentSideMenu("")}
                        >
                          <ListItem button  style={{pointerEvents: "none"}}>
                            <Link style={{width: "20REM", background: 'transparent', fontSize: 14}}>              
                              User management
                            </Link>
                          </ListItem>
                        </div>
                      )}
                      <div
                          onMouseEnter={() => setCurrentSideMenu("algorithms")}
                          onMouseLeave={() => setCurrentSideMenu("")}
                      >
                        <ListItem button  style={{pointerEvents: "none"}}>
                          <Link style={{width: "20REM", background: 'transparent', fontSize: 14}}>              
                            Algorithms
                          </Link>
                        </ListItem>
                      </div>

                      {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                      <ListItem button>
                        <Link 
                          onClick={() => {
                            history.push("/manageAlgorithmClassNames");
                            setCurrentSideMenu("");
                            window.location.reload();
                          }}
                          style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                        >
                          Manage algorithm class names
                        </Link>
                      </ListItem>
                      )}

                    </div>
                  </Grid>

                  {currentSideMenu == "cohortManagement"?                
                  <Grid 
                    onMouseEnter={() => setCurrentSideMenu("cohortManagement")}
                    onMouseLeave={() => setCurrentSideMenu("")}
                    item xs={6}
                  >
                    {(Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchSupport
                    || Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchTestSupport) && (
                    <ListItem button>
                      <Link 
                        onClick={() => {
                          history.push("/configRemoteCreateCohort");
                          setCurrentSideMenu("");
                          window.location.reload();
                        }}
                        style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                      >
                        Remote create cohorts
                      </Link>
                    </ListItem>
                    )}
                    {(Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchSupport
                    || Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchTestSupport)
                    && (parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin
                        || parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator) && (
                    <ListItem button>
                      <Link 
                        onClick={() => {
                          history.push("/configLimitedTorchUsers");
                          setCurrentSideMenu("");
                        }}
                        style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                      >
                        Limited torch users to cohort mapping
                      </Link>
                    </ListItem>
                    )}
                    {(Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchSupport
                      || Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchTestSupport) && (
                    <ListItem button>
                      <Link 
                        onClick={() => {
                          history.push("/configCohortCreateMonitoring");
                          setCurrentSideMenu("");
                          window.location.reload();
                        }}
                        style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                      >
                        Cohort creation monitoring
                      </Link>
                    </ListItem>
                    )}
                  </Grid> : <div></div>}
                  
                  {currentSideMenu == "userManagement"?                
                  <Grid 
                    onMouseEnter={() => setCurrentSideMenu("userManagement")}
                    onMouseLeave={() => setCurrentSideMenu("")}
                    item xs={6}
                  >
                    {(Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchSupport
                      || Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchTestSupport) && (
                      <ListItem button>
                      <Link 
                        onClick={() => {
                          history.push("/configManageLimitedTorchUsers");
                          setCurrentSideMenu("");
                        }}
                        style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                      >
                        Manage limited torch users
                      </Link>
                    </ListItem>
                    )}
                  </Grid> : <div></div>}
                  
                  {currentSideMenu == "algorithms"?                
                  <Grid 
                    onMouseEnter={() => setCurrentSideMenu("algorithms")}
                    onMouseLeave={() => setCurrentSideMenu("")}
                    item xs={6}
                  >
                    {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                      <ListItem button>
                        <Link 
                          onClick={() => {
                            history.push("/configEditAccountRecommendationALGConfig");
                            setCurrentSideMenu("");
                            window.location.reload();
                          }}
                          style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                        >              
                          Edit Account Recommendation ALG Config

                        </Link>
                      </ListItem>
                    )}
                    {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                      <ListItem button>
                        <Link 
                          onClick={() => {
                            history.push("/configEditAccountCheckALGConfig");
                            setCurrentSideMenu("");
                            window.location.reload();
                          }}
                          style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                        >              
                          Edit Account Checking ALG Config
                        </Link>
                      </ListItem>
                    )}
                    {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                      <ListItem button>
                        <Link 
                          onClick={() => {
                            history.push("/configEditDisplayRecommendationConfig");
                            setCurrentSideMenu("");
                            window.location.reload();
                          }}
                          style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                        >
                          Edit display recommendations
                        </Link>
                      </ListItem>
                    )}
                    {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                      <ListItem button>
                        <Link 
                          onClick={() => {
                            history.push("/configEditAccountModuleConfig");
                            setCurrentSideMenu("");
                            window.location.reload();
                          }}
                          style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                        >
                          Edit Account Module Config
                        </Link>
                      </ListItem>
                    )}
                    {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                      <ListItem button>
                        <Link 
                          onClick={() => {
                            history.push("/configEditAccountPOCConfig");
                            setCurrentSideMenu("");
                            window.location.reload();
                          }}
                          style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                        >              
                          Edit POC configuration
                        </Link>
                      </ListItem>
                    )}
                    {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                      <ListItem button>
                        <Link 
                          onClick={() => {
                            history.push(`/AlgorithmStatusTable?${Configs.algorithmStatusTableSearch.recommendationSearch}`);
                            setCurrentSideMenu("");
                            window.location.reload();
                          }}
                          style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                        >              
                          Edit recommendation algorithm status table
                        </Link>
                      </ListItem>
                    )}
                    {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                      <ListItem button>
                        <Link 
                          onClick={() => {
                            history.push(`/AlgorithmStatusTable?${Configs.algorithmStatusTableSearch.checkingSearch}`);
                            setCurrentSideMenu("");
                            window.location.reload();
                          }}
                          style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                        >              
                          Edit checking algorithm status table
                        </Link>
                      </ListItem>
                    )}
                    {Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType != Configs.supportSiteType.SC4CSupport 
                    && (parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator
                        || parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandE) && (
                      <ListItem button>
                        <Link 
                          onClick={() => {
                            history.push("/configViewAccountRecommendation");
                            setCurrentSideMenu("");
                            window.location.reload();
                          }}
                          style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                        >              
                          View Account Recommendation ALG Config
                        </Link>
                      </ListItem>
                    )}
                    {Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType != Configs.supportSiteType.SC4CSupport 
                    && (parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator
                        || parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandE) && (
                      <ListItem button>
                        <Link 
                          onClick={() => {
                            history.push("/configViewDisplayRecommendation");
                            setCurrentSideMenu("");
                            window.location.reload();
                          }}
                          style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                        >              
                          View display recommendations
                        </Link>
                      </ListItem>
                    )}
                    {Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType != Configs.supportSiteType.SC4CSupport 
                    && (parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator
                        || parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandE) && (
                      <ListItem button>
                        <Link 
                          onClick={() => {
                            history.push(`/AlgorithmStatusTable?${Configs.algorithmStatusTableSearch.recommendationSearch}`);
                            setCurrentSideMenu("");
                            window.location.reload();
                          }}
                          style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                        >              
                          View recommendation algorithm status table
                        </Link>
                      </ListItem>
                    )}
                    {Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType != Configs.supportSiteType.SC4CSupport 
                    && (parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator
                        || parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandE) && (
                      <ListItem button>
                        <Link 
                          onClick={() => {
                            history.push(`/AlgorithmStatusTable?${Configs.algorithmStatusTableSearch.checkingSearch}`);
                            setCurrentSideMenu("");
                            window.location.reload();
                          }}
                          style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                        >              
                          View checking algorithm status table
                        </Link>
                      </ListItem>
                    )}
                  </Grid> : <div></div>}
                </Grid>
              </div>
            </div>
            )}

            {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
            <div style={{ flexGrow: 1, marginRight: 30 }} className="dropdownTorch">
              <Link 
                to="#"
                style={{ width: siteMenuWidth, background: 'transparent', fontSize: 14, }}
                className="dropbtnTorch"
              >              
                TOOLS
              </Link>
              <div
                style={{ height: '1500%', width: siteMenuWidth }}
                className="dropdownTorch-content"
              >
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <div style={{ height: '1500%', width: "15REM" }}>
                      <ListItem 
                        button
                        style = {{width: "85%"}}
                      >
                        <Link 
                          onClick={() => history.push("/configInstallCommandEncryptDecrypt")}
                          style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                        >              
                          Install Command/Encrypt Decrypt
                        </Link>
                      </ListItem>
                      <div
                          onMouseEnter={() => setCurrentSideMenu("databaseTools")}
                          onMouseLeave={() => setCurrentSideMenu("")}
                      >
                        <ListItem button  style={{pointerEvents: "none"}}>
                          <Link style={{width: "20REM", background: 'transparent', fontSize: 14}}>              
                            Database tools
                          </Link>
                        </ListItem>
                      </div>
                    </div>
                  </Grid>

                  {currentSideMenu == "databaseTools"?                
                  <Grid 
                    onMouseEnter={() => setCurrentSideMenu("databaseTools")}
                    onMouseLeave={() => setCurrentSideMenu("")}
                    item xs={6}
                  >
                  <ListItem button>
                    <Link 
                      onClick={() => {
                        history.push("/configAdminDBActions");
                        setCurrentSideMenu("");
                      }}
                      style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                    >              
                      Admin DB actions
                    </Link>
                  </ListItem>
                  <ListItem button>
                    <Link 
                      onClick={() => {
                        history.push("/configStaffExtractDuplicate");
                        setCurrentSideMenu("");
                      }}
                      style={{ width: "20REM", background: 'transparent', fontSize: 14, }}
                    >
                      Staff extract duplicate issue
                    </Link>
                  </ListItem>
                  </Grid> : <div></div>}                  
                </Grid>
              </div>
            </div>
            )}
            </Typography>          
          <Button color="inherit" onClick={() => props.CallbackSignout()}>
            {bt}
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
