// Description: This file is responsible for rendering the API summary page.
// Status: Ready

import React, { Component } from "react";
import FetchApi from "../Service/FetchApi";
import MaterialTable from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Configs from "../Service/Configuration";
import { utcToLocal, verifyHours, utcToAusLocal } from "./HelperFunctions";

const styling = {
  width3em: {
    width: "3em",
  },
  center: {
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
};

class APISummary extends Component {
  constructor(props) {
    super();
    this.state = {
      DisplayTable: null,
      status: props.match.params.Status,
      versionNumber: props.match.params.Number,
      TableCol: [
        { title: "Site Name", field: "fhtSiteName" },
        { title: "Machine Name", field: "siteMachineName" },
        { title: "Version", field: "fhtHeartbeatVersionNumber" },
        { title: "Last webapi heartbeat", field: "fhtHeartbeatLastlog" },
        { title: "Last autoupdater heartbeat", field: "latestAutoupdaterHeartbeatForMachine" },
        {
          title: "Status",
          field: "status",
        },
      ],
      isLoaded: false,
      activeTime: 1,
    };

    this.pageOptions = {
      search: true,
      searchText:
        props.location.state === undefined
          ? null
          : props.location.state.Keyword,
      filtering: true,
      pageSize: 10,
      pageSizeOptions: [10, 20, 30],
      exportButton: true,
      exportAllData: true,
    };
  }

  componentDidMount = async () => {
    if(this.state.status != null){
      if (this.state.status.includes("active") || this.state.status.includes("fail")) {
        var updatedStatusFilter =  this.state.TableCol.filter(a => a.title == "Status")[0]
        updatedStatusFilter["defaultFilter"] = this.state.status
        this.state.TableCol.filter(a => a.title == "Version")[0] = updatedStatusFilter
     }
    }
    if (this.state.versionNumber != null) {
      var updatedVersionFilter =  this.state.TableCol.filter(a => a.title == "Version")[0]
      updatedVersionFilter["defaultFilter"] = this.state.versionNumber
      this.state.TableCol.filter(a => a.title == "Version")[0] = updatedVersionFilter 
    }
    this.getAPISummaryStatus();
  };

  getAPISummaryStatus = async () => {    
    const api = FetchApi(Configs.api.getAPISummaryHourFiltering);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
      HourFilterCheck: this.state.activeTime
    };
    api.post(body).then((response) => {
      console.log("getAPISummary response", response);
      const siteVersions = response.webAPISiteVers;
      const fullTable = response.summary;
      const postTable = this.afterFiltering(fullTable);
      this.setState({
        originalTable : fullTable,
        DisplayTable : postTable,
        isLoaded : true,
      })
    })
  }

  changeMaxStatus = (event) => {
    this.setState({
      activeTime: event.target.value
    }, () => {      
      setTimeout(function () {
        this.getAPISummaryStatus()
      }.bind(this), 1000)
    })
  };

  afterFiltering = (fullTable) => {
    const filterValue = Object.values(this.props.match.params)[0];
    const newTable = [];
    fullTable.forEach((record) => {
      if (filterValue === undefined) {
        newTable.push(record);
      } else if (record["status"] === filterValue) {
        newTable.push(record);
      } else if (filterValue === record["fhtHeartbeatVersionNumber"]) {
        newTable.push(record);
      }
    });

    return newTable;
  };

  render() {
    const { isLoaded, DisplayTable } = this.state;

    if (isLoaded) {
      return (
        <div>
          <div style={styling.center}>
            *Status: A FHT API site is active if its last log was received
            within the last{"  "}
            <TextField
              type="number"
              size="small"
              defaultValue={1}
              onChange={this.changeMaxStatus}
              style={styling.width3em}
            />
            hour.
          </div>
          <MaterialTable
            columns={this.state.TableCol}
            data={DisplayTable}
            options={this.pageOptions}
            title="WebAPI version"
          ></MaterialTable>
        </div>
      );
    } else {
      return (
        <div>
          <CircularProgress />
          Loading...
        </div>
      );
    }
  }
}

export default APISummary;
