import React, { Component } from "react";

import { Grid } from "@material-ui/core";
import FetchApi from "../Service/FetchApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CSVLink } from "react-csv";
import Configs from "../Service/Configuration";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {
  removeArrayDuplicates,
  utcToAESTLocal
} from "./HelperFunctions";

const styling = {
  center: {
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  width3em: {
    width: "3em",
  },
};

class CSVDownload extends Component {
  constructor(props) {
    super();
    this.state = {
      isLoaded: false,
      isLoadingALGCSV : false,
      downloadLogMonths : 1,
      downloadCSVUsageDataMonths: 1,
      downloadCSVCohortReportMonths: 1,
      loadingMessage: "Fetching data from the server...",
    };
  }

  cohortReportLinkClicked = React.createRef();
  usageDataLinkClicked = React.createRef();
  demographicsReportClicked = React.createRef();
  cohortRecommendationClicked = React.createRef();
  ckdReportClicked = React.createRef();
  uqradReportClicked = React.createRef();
  uqExclusionReportClicked = React.createRef();
  jan2020sep2020POCCountReportClicked = React.createRef();

  
  generateDemographicsCSV = async () => {
    try {
      // Ensure we query the site only once per page refresh.
      if (this.state.CSVDemoFile == undefined) {
        this.generateSnackBarMessage();
        let body = {
          SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
        };
        await FetchApi("GetDemographicCSV").post(body).then((fetched) => {
          const csvReport = {
            data: fetched,
            filename: "Demographics_Report.csv"
          };
          console.log("Demographics", csvReport);
          this.setState({
            CSVDemoFile: csvReport
          });
          this.demographicsReportClicked.current.link.click();
        });
      } else {
        this.demographicsReportClicked.current.link.click();
      }
    } catch (e) {
      console.log("Demographics fetch error:" + e);
      return false;
    }
    return true;
  }

  generateCKDCSV = async () => {
    try {
      // Ensure we query the site only once per page refresh.
      if (this.state.CSVCKDFile == undefined) {
        this.generateSnackBarMessage();
        let body = {
          SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
        };
        await FetchApi("GetCKDCSV").post(body).then((fetched) => {
          const csvReport = {
            data: fetched,
            filename: "CKD_Report.csv"
          };
          this.setState({
            CSVCKDFile: csvReport
          });
        });
        console.log("CKD CSV generated", this.state.CSVCKDFile);
        this.ckdReportClicked.current.link.click();
      } else {
        this.ckdReportClicked.current.link.click();
      }
    } catch (e) {
      console.log("CKD fetch error:" + e);
      return false;
    }
    return true;
  }

  generateUQRADCSV = async () => {
    try {
      // Ensure we query the site only once per page refresh.
      if (this.state.CSVUQRADFile == undefined) {
        this.generateSnackBarMessage();
        let body = {
          SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
        };
        await FetchApi("GetUQRADCSV").post(body).then((fetched) => {
          const csvReport = {
            data: fetched,
            filename: "UQRAD_Report.csv"
          };
          this.setState({
            CSVUQRADFile: csvReport
          });
        });
        console.log("UQRAD CSV generated", this.state.CSVUQRADFile);
        this.uqradReportClicked.current.link.click();
      } else {
        this.uqradReportClicked.current.link.click();
      }
    } catch (e) {
      console.log("UQRAD fetch error:" + e);
      return false;
    }
    return true;
  }
  generateUQExclusionCSV = async () => {
    try {
      // Ensure we query the site only once per page refresh.
      if (this.state.CSVUQExclusionFile == undefined) {
        this.generateSnackBarMessage();
        let body = {
          SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
        };
        await FetchApi("GetUQExclusionCSV").post(body).then((fetched) => {
          const csvReport = {
            data: fetched,
            filename: "UQExclusion_Report.csv"
          };
          this.setState({
            CSVUQExclusionFile: csvReport
          });
        });
        console.log("UQExclusion CSV generated", this.state.CSVUQExclusionFile);
        this.uqExclusionReportClicked.current.link.click();
      } else {
        this.uqExclusionReportClicked.current.link.click();
      }
    } catch (e) {
      console.log("UQExclusion fetch error:" + e);
      return false;
    }
    return true;
  }
  generateJan2020Sep2020POCCountsCSV = async () => {
    try {
      // Ensure we query the site only once per page refresh.
      if (this.state.CSVJan2020Sep2020POCCountFile == undefined) {
        this.generateSnackBarMessage();
        let body = {
          SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
        };
        await FetchApi("GetJan2020Sep2020POCCountCSV").post(body).then((fetched) => {
          const jan2020sep2020POCCountReport = {
            data: fetched,
            filename: "Jan2020Sep2020POCCount_Report.csv"
          };
          this.setState({
            CSVJan2020Sep2020POCCountFile: jan2020sep2020POCCountReport
          });
        });
        this.jan2020sep2020POCCountReportClicked.current.link.click();
      } else {
        this.jan2020sep2020POCCountReportClicked.current.link.click();
      }
    } catch (e) {
      console.log("POC count fetch error:" + e);
      return false;
    }
    return true;
  }

  generateCohortRecommendationCSV = async () => {
    try {
      // Ensure we query the site only once per page refresh.
      if (this.state.CSVCohortRecFile == undefined) {
        this.generateSnackBarMessage();
        let body = {
          SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
          StatusReportSoftwareType: Configs.SiteConfigurationMapping[window.location.hostname].statusReportSoftwareType,
        };
        await FetchApi("GetCohortRecommendationCSV").post(body).then((fetched) => {
          const csvReport = {
            data: fetched,
            filename: "Cohort_Recommendations_Report.csv"
          };
          this.setState({
            CSVCohortRecFile: csvReport
          });
        });
        console.log("Cohort CSV generated", this.state.CSVCohortRecFile);
        this.cohortRecommendationClicked.current.link.click();
      } else {
        this.cohortRecommendationClicked.current.link.click();
      }
    } catch (e) {
      console.log("Cohort fetch error:" + e);
      return false;
    }
    return true;
  }

  generateCohortReportCSV = async () => {
    try {
      // Ensure we query the site only once per page refresh.
      // if (this.state.CSVCohortReportFile == undefined) {
        this.generateSnackBarMessage();
        let body = {
          SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
          CSVCohortReportMonths: this.state.downloadCSVCohortReportMonths,
          StatusReportSoftwareType: Configs.SiteConfigurationMapping[window.location.hostname].statusReportSoftwareType,          
        };
        await FetchApi("GetCohortReportCSV").post(body).then((fetched) => {
          const csvReport = {
            data: fetched,
            filename: "Cohort_Report.csv"
          };
          this.setState({
            CSVCohortReportFile: csvReport
          });
          this.cohortReportLinkClicked.current.link.click();
        });
        console.log("Cohort report CSV generated", this.state.CSVCohortReportFile);
      // } else {
      //   this.cohortReportLinkClicked.current.link.click();
      // }
    } catch (e) {
      console.log("Cohort fetch error:" + e);
      return false;
    }
    return true;
  }

  generateUsageCSV = async () => {
    try {
      // 15-9-2022 - Currently allow multiple download attempts, due to new date filtering.
      // Previously logic was to allow to download only once per page refresh, due to downloading entire API usage.
      // if (this.state.CSVUsageFile == undefined) {
        this.generateSnackBarMessage();
        let body = {
          SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
          CSVUsageDataMonths: this.state.downloadCSVUsageDataMonths,
        };
        await FetchApi("GetUsageCSV").post(body).then((fetched) => {
          const csvReport = {
            data: fetched,
            filename: "Usage_Report.csv"
          };
          this.setState({
            CSVUsageFile: csvReport
          });
        });
        console.log("Usage CSV generated", this.state.CSVUsageFile);
        this.usageDataLinkClicked.current.link.click();
      // } else {
      //   this.usageDataLinkClicked.current.link.click();
      // }
    } catch (e) {
      console.log("Usage fetch error:" + e);
      return false;
    }
    return true;
  }

  generateSnackBarMessage() {
    this.props.GlobalFunctionDisplaySnackbarMessage(
      "Data is currently being processed.",
      Configs.snackbarVariants.success
    );
  }
  
  //xx
  downloadLogClicked = React.createRef();
  getAllSiteALGHistory = async(months) =>{
    this.setState({isLoadingALGCSV:true});
    const api = FetchApi("GetAllAlgorithmHistory/months/" + months);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    
    await api.post(body).then((response)=>{
      console.log(response);
      this.setState({AllSiteALGHistory : {data : response}})
    })
    this.downloadLogClicked.current.link.click();
    this.setState({isLoadingALGCSV:false});
  }

  render() {
    console.log ("CSVDownload page sitetypeID:",Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID);
    const {
      loadingMessage,
      
      isLoadingALGCSV,
      downloadLogMonths,
      AllSiteALGHistory = {
        data: [],
        filename: "ALGHistory_1m.csv"
      },
      
      CSVCohortReportFile = {
        data: [],
        filename: "Cohort_Report.csv"
      },
      CSVUsageFile = {
        data: [],
        filename: "Usage_Report.csv"
      },
      CSVDemoFile = {
        data: [],
        filename: "Demographics_Report.csv"
      },
      CSVCohortRecFile = {
        data: [],
        filename: "Cohort_Recommendations_Report.csv"
      },
      CSVCKDFile = {
        data: [],
        filename: "CKD_Report.csv"
      },
      CSVUQRADFile = {
        data: [],
        filename:"UQRAD_Report.csv"
      },
      CSVJan2020Sep2020POCCountFile = {
        data: [],
        filename: "Jan2020Sep2020POCCount_Report.csv"
      },
      CSVUQExclusionFile = {
        data: [],
        filename:"UQExclusion_Report.csv"
      }
    } = this.state;


    return (
      <div>
        <Grid container spacing={10} alignItems="center" justify="center">
          <Grid>
            <br />
            <br />
            <br />
            <br />
            <div>
              <Button
                variant="contained"
                onClick={this.generateDemographicsCSV}>
                Download Demographic data
              </Button>
              <CSVLink
                data={CSVDemoFile.data}
                filename="Demographics_Report.csv"
                className="hidden"
                ref={this.demographicsReportClicked}
                target="_blank"
              />
            </div>
            <br />
            <br />
              <div>										
                <TextField 
                  label="Months of data from today" 
                  type="number"
                  variant="outlined"
                  value={this.state.downloadCSVUsageDataMonths}
                  onChange={(event) => {
                    this.setState( { downloadCSVUsageDataMonths: event.target.value});
                  }}
                />
                <span> &nbsp; </span>
                <Button
                  style={{maxHeight: '60px', minHeight: '60px'}}
                  variant="contained"
                  onClick={this.generateUsageCSV}>
                  Download Usage data
                </Button>
                <div style={{ fontSize: "75%" }}>Please note that this data set is large and can take upwards of a minute to process.</div>
                <CSVLink
                  data={CSVUsageFile.data}
                  filename="Usage_Report.csv"
                  className="hidden"
                  ref={this.usageDataLinkClicked}
                  target="_blank"
                />
              </div>
            <br />
            <br />
            {((Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchSupport ||
            Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchTestSupport)) &&

              <div>
                <Button
                  variant="contained"
                  onClick={this.generateUQRADCSV}>
                  Download UQ-Release Antidepressant data
                </Button>
                <CSVLink
                  data={CSVUQRADFile.data}
                  filename="UQRAD_Report.csv"
                  className="hidden"
                  ref={this.uqradReportClicked}
                  target="_blank"
                />
              </div>

            }
            <br />
            <br />
            {((Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchSupport ||
            Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchTestSupport)) &&

              <div>
                <Button
                  variant="contained"
                  onClick={this.generateUQExclusionCSV}>
                  Download UQ-Release Exclusion data
                </Button>
                <CSVLink
                  data={CSVUQExclusionFile.data}
                  filename="UQExclusion_Report.csv"
                  className="hidden"
                  ref={this.uqExclusionReportClicked}
                  target="_blank"
                />
              </div>

            }

            {/* Only display these download options for FHT site types */}
            {(Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchSupport ||
            Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchTestSupport) ? 
            <div></div> :
            <div>
              <div>
              <TextField 
                  label="Months of data from today" 
                  type="number"
                  variant="outlined"
                  value={this.state.downloadCSVCohortReportMonths}
                  onChange={(event) => {
                    this.setState( { downloadCSVCohortReportMonths: event.target.value});
                  }}
                /><span> &nbsp; </span>
                <Button
                  style={{maxHeight: '60px', minHeight: '60px'}}
                  variant="contained"
                  onClick={this.generateCohortReportCSV}>
                  Download Cohort data
                </Button>
                <CSVLink
                  data={CSVCohortReportFile.data}
                  filename="Cohort_Report.csv"
                  className="hidden"
                  ref={this.cohortReportLinkClicked}
                  target="_blank"
                />
              </div>
            <br />
            <br />
              <div>
                <Button
                  variant="contained"
                  onClick={this.generateCohortRecommendationCSV}>
                  Download Cohort Recommendations data
                </Button>
                <CSVLink
                  data={CSVCohortRecFile.data}
                  filename="Cohort_Recommendations_Report.csv"
                  className="hidden"
                  ref={this.cohortRecommendationClicked}
                  target="_blank"
                />
              </div>
            <br />
            <br />
              <div>
                <Button
                  variant="contained"
                  onClick={this.generateCKDCSV}>
                  Download CKD data
                </Button>
                <CSVLink
                  data={CSVCKDFile.data}
                  filename="CKD_Report.csv"
                  className="hidden"
                  ref={this.ckdReportClicked}
                  target="_blank"
                />
              </div>
            <br />
         
            <br />
              {/* On production, receive 504 error. Not viable to allow users to download this
              large data set. Hiding so only developers can access this button/route. */}
              {/* <div>
                <Button
                  variant="contained"
                  onClick={this.generateJan2020Sep2020POCCountsCSV}>
                  Download POC counts from Jan 2020 to Sep 2020
                </Button>
                <div style={{ fontSize: "75%" }}>Please note that this data set is large and can take 10 minutes to process.</div>
                <CSVLink
                  data={CSVJan2020Sep2020POCCountFile.data}
                  filename="Jan2020Sep2020POCCount_Report.csv"
                  className="hidden"
                  ref={this.jan2020sep2020POCCountReportClicked}
                  target="_blank"
                />
              </div>
            <br />
            <br /> */}
              {Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType === Configs.supportSiteType.SC4CSupport ? 
                <div/> :
                <div >
                  <TextField 
                    label="Months of data from today" 
                    type="number"
                    variant="outlined"
                    value={downloadLogMonths}
                    onChange={(event) => {
                      this.setState( { downloadLogMonths: event.target.value});
                    }}
                  />
                  <span> &nbsp; </span>
                  <Button 
                    variant="contained"
                    style={{maxHeight: '60px', minHeight: '60px'}}
                    disabled={isLoadingALGCSV}
                    onClick={()=>{this.getAllSiteALGHistory(downloadLogMonths)}}>
                    {(isLoadingALGCSV) 
                    ? <CircularProgress/>
                    : "Download all sites ALG logs"
                    }
                  </Button>
                  <CSVLink
                        data={AllSiteALGHistory.data}
                        filename={"ALGHistory_" + downloadLogMonths + "m.csv"}
                        className="hidden"
                        ref={this.downloadLogClicked}
                        target="_blank"
                  />
                </div>              
              }
            </div>}
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default CSVDownload;