const siteTypeID = {
  "fhtSites": 1,
  "torchSites": 2,
  "SC4CSites" : 3, 
  "fhtTestSites" : 4,
  "torchTestSites" : 5,
}

const statusReportSoftwareType = {
  "FHT": 1,
  "Torch": 2,
 }

const Configs = {
  version: 1,
  fileServer: "https://fileserver.futurehealthtoday.com.au/",
  tokenInfo: "tokenInfo",
  // Either fhtSupport or torchSupport
  // Maybe change SiteType to an ID -> send this via POST to DB to get specific sites
  // i.e Torch sites/Program sites etc...
  "SiteConfigurationMapping":{
    "localhost": {
      
      // "currentSiteType": "fhtSupport", 
      // "siteTypeID": [siteTypeID["fhtSites"]],
      // "accountCreationSiteTypeID": siteTypeID["fhtSites"],
      // "statusReportSoftwareType": statusReportSoftwareType["FHT"],
      // "statusReportFeatureSwitch" : "on",
      // "ApplyAllTorchCohortSimilarTrials": "true",
      // "DisableAlgorithmStatusTable": "false",

      // "currentSiteType": "SC4CSupport",
      // "siteTypeID": [siteTypeID["SC4CSites"]],
      // "accountCreationSiteTypeID": siteTypeID["torchSites"],
      // "ApplyAllTorchCohortSimilarTrials": "false",
      // "DisableAlgorithmStatusTable": "false",
      
      "currentSiteType": "torchSupport", 
      "siteTypeID": [siteTypeID["torchSites"]],
      "accountCreationSiteTypeID": siteTypeID["torchSites"],
      "statusReportSoftwareType": statusReportSoftwareType["Torch"],
      "ApplyAllTorchCohortSimilarTrials": "false",
      "DisableAlgorithmStatusTable": "false",
      
      // "currentSiteType": "fhtTestSupport", 
      // "siteTypeID": [siteTypeID["fhtTestSites"]],
      // "accountCreationSiteTypeID": siteTypeID["fhtTestSites"],
      // "statusReportSoftwareType": statusReportSoftwareType["FHT"],
      // "statusReportFeatureSwitch" : "on",
      // "ApplyAllTorchCohortSimilarTrials": "false",
      // "DisableAlgorithmStatusTable": "false",

      // "currentSiteType": "torchTestSupport", 
      // "siteTypeID": [siteTypeID["torchTestSites"]],
      // "accountCreationSiteTypeID": siteTypeID["torchTestSites"],
      // "statusReportSoftwareType": statusReportSoftwareType["Torch"],
      // "ApplyAllTorchCohortSimilarTrials": "true",
      // "DisableAlgorithmStatusTable": "false",
      
      "awsUrl": "http://localhost:57502/",
      // "awsUrl": "https://api.futurehealthtoday.com.au/",
    },
    
    "report.futurehealthtoday.com.au": { 
      "currentSiteType": "fhtSupport", 
      "siteTypeID": [siteTypeID["fhtSites"]],
      "accountCreationSiteTypeID": siteTypeID["fhtSites"],
      "statusReportSoftwareType": statusReportSoftwareType["FHT"],
      "awsUrl": "https://api.futurehealthtoday.com.au/",
      "statusReportFeatureSwitch" : "on",
      "ApplyAllTorchCohortSimilarTrials": "false",
      "DisableAlgorithmStatusTable": "true",
    },
    
    "testinaws.dy1yd7gioveuf.amplifyapp.com": { 
      
       "currentSiteType": "fhtSupport", 
       "siteTypeID": [siteTypeID["fhtSites"]],
       "accountCreationSiteTypeID": siteTypeID["fhtSites"],
       "statusReportSoftwareType": statusReportSoftwareType["FHT"],
       "statusReportFeatureSwitch" : "on",
       "ApplyAllTorchCohortSimilarTrials": "false",
       "DisableAlgorithmStatusTable": "true",
      
      // "currentSiteType": "SC4CSupport",
      // "siteTypeID": [siteTypeID["SC4CSites"]],
      // "accountCreationSiteTypeID": siteTypeID["torchSites"],
      // "ApplyAllTorchCohortSimilarTrials": "false",
      // "DisableAlgorithmStatusTable": "true",

      // "currentSiteType": "torchSupport", 
      // "siteTypeID": [siteTypeID["torchSites"]],
      // "accountCreationSiteTypeID": siteTypeID["torchSites"],
      // "statusReportSoftwareType": statusReportSoftwareType["Torch"],
      // "ApplyAllTorchCohortSimilarTrials": "false",
      // "DisableAlgorithmStatusTable": "true",

      //"currentSiteType": "fhtTestSupport", 
      //"siteTypeID": [siteTypeID["fhtTestSites"]],
      //"accountCreationSiteTypeID": siteTypeID["fhtTestSites"],
      //"statusReportSoftwareType": statusReportSoftwareType["FHT"],
      //"statusReportFeatureSwitch" : "off",
      // "ApplyAllTorchCohortSimilarTrials": "false",
      // "DisableAlgorithmStatusTable": "true",

      // "currentSiteType": "torchTestSupport", 
      // "siteTypeID": [siteTypeID["torchTestSites"]],
      // "accountCreationSiteTypeID": siteTypeID["torchTestSites"],
      // "statusReportSoftwareType": statusReportSoftwareType["Torch"],
      // "ApplyAllTorchCohortSimilarTrials": "false",
      // "DisableAlgorithmStatusTable": "true",
      
      "awsUrl": "https://api2.futurehealthtoday.com.au/",
    },
    "report.torchrecruit.com.au": { 
      "currentSiteType": "torchSupport", 
      "siteTypeID": [siteTypeID["torchSites"]],
      "accountCreationSiteTypeID": siteTypeID["torchSites"],
      "statusReportSoftwareType": statusReportSoftwareType["Torch"],
      "ApplyAllTorchCohortSimilarTrials": "true",
      "awsUrl": "https://api.futurehealthtoday.com.au/",
      "DisableAlgorithmStatusTable": "false",
    },
    "reportsc4c.futurehealthtoday.com.au" : { // -- Remember to change to correct url -- //
      "currentSiteType": "SC4CSupport",
      "siteTypeID": [siteTypeID["SC4CSites"]],
      "accountCreationSiteTypeID": siteTypeID["SC4CSites"],
      "ApplyAllTorchCohortSimilarTrials": "false",
      "awsUrl": "https://api.futurehealthtoday.com.au/",
      "DisableAlgorithmStatusTable": "true",
    },
    "testreport.futurehealthtoday.com.au": { 
      "currentSiteType": "fhtTestSupport", 
      "siteTypeID": [siteTypeID["fhtTestSites"]],
      "accountCreationSiteTypeID": siteTypeID["fhtTestSites"],
      "statusReportSoftwareType": statusReportSoftwareType["FHT"],
      "ApplyAllTorchCohortSimilarTrials": "false",
      "awsUrl": "https://api.futurehealthtoday.com.au/",
      "statusReportFeatureSwitch" : "on",
      "DisableAlgorithmStatusTable": "false",
    },
    "testreport.torchrecruit.com.au": { 
      "currentSiteType": "torchTestSupport", 
      "siteTypeID": [siteTypeID["torchTestSites"]],
      "accountCreationSiteTypeID": siteTypeID["torchTestSites"],
      "statusReportSoftwareType": statusReportSoftwareType["Torch"],
      "ApplyAllTorchCohortSimilarTrials": "true",
      "awsUrl": "https://api.futurehealthtoday.com.au/",
      "DisableAlgorithmStatusTable": "false",
    },
  },
  urlHeader: {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "cache-control": "no-cache",
    },
  },
  state: {
    "Australian Capital Territory": "Australian Capital Territory",
    "New South Wales": "New South Wales",
    "Northern Territory": "Northern Territory",
    Queensland: "Queensland",
    "South Australia": "South Australia",
    Tasmania: "Tasmania",
    Victoria: "Victoria",
    "Western Australia": "Western Australia",
  },
  emr: {
    zedmed: "Zedmed",
    bp: "Best practice",
    md: "Medical Director",
  },
  supportSiteType: {
    fhtSupport: "fhtSupport",
    torchSupport: "torchSupport",
    SC4CSupport : "SC4CSupport",
    fhtTestSupport : "fhtTestSupport",
    torchTestSupport : "torchTestSupport",
  },
  accountType: { //not used
    server: "Server",
    client: "Client",
  },
  featureSwitch: {
    on:"on",
    off:"off",
  },
  fhtSupportRoleType: {
    fhtSiteAccountNoAccess: 0,
    fhtSupportAdmin: 1,
    fhtSupportIandE: 2,
    fhtSupportDisabledAccount: 3,
    fhtSupportIandECoordinator: 4,
  },
  isService: {
    0: "No",
    1: "Yes",
  },
  yesNo: { // Not used 
    0: "No",
    1: "Yes",
  },
  queryStatus: {
    1: "Open",
    2: "Close",
  },
  serviceCommand: { //Not used
    1: "Start",
    2: "Stop",
  },
  mappingStatus: {
    true: "Yes",
    false: "No",
  },
  softwareType: { //not used
    service: "service",
    wpf: "wpf",
    console: "console",
  },
  prodStatus:{
    1:"Yes",
    2:"No"
  },
  api: {
    userLogin: "login",
    checkValidTokenLink: "CheckValidToken",
    // getSite: "GetAllSites",
    getAccountSummary: "GetAccountSummary",                 // not used in fhtsupwesite
    getCurrentSoftwareVersion: "GetCurrentSoftwareVersion", // not used in fhtsupwesite
    getAccount: "GetAccount",                               //not used in fhtsupwesite
    getAccountConfigs : "GetAccountConfigs",
    updateAccountConfigs : "UpdateAccountConfigs",
    getConfigAlgoMap : "GetConfigAlgoMap",

    // Remove this, split into two
    getSiteAlgoMapAndSiteList : "GetSiteAlgoMappingAndSiteList",
    postSiteAlgoMap : "PostSiteAlgoMapping",
    postMultipleSiteAlgoMap : "PostMultipleSiteAlgoMapping",
    getSiteList: "GetSiteList",                             // not used in fhtsupwesite
    getSoftware: "GetSoftwareList",                         // not used in fhtsupwesite
    getSoftwareMapping: "GetSoftwareMapping",
    getInstallSoftware: "GetInstallSoftware",               // not used in fhtsupwesite
    getSoftwareConfig: "GetSoftwareConfig",                 // not used in fhtsupwesite
    getReleasedSoftware: "GetReleasedSoftware",
    getBuildVersion: "GetBuildVersion",
    getMachine: "getMachine",                               // not used in fhtsupwesite
    getMachineLogs : "GetMachineLogs",
    getUninstall: "GetUninstall",                           // not used in fhtsupwesite
    updateSite: "UpdateSite",                               // not used in fhtsupwesite
    updateSoftware: "UpdateSoftware",                       // not used in fhtsupwesite
    updateAccount: "UpdateAccount",                         // not used in fhtsupwesite
    updateInstallSoftware: "UpdateInstallSoftware",         // not used in fhtsupwesite
    updateSoftwareConfig: "UpdateSoftwareConfig",           // not used in fhtsupwesite
    updateReleaseVersion: "UpdateReleaseVersion",           // not used in fhtsupwesite
    uninstallSoftware: "UninstallSoftware",                 // not used in fhtsupwesite
    updateCommmand: "UpdateCommmand",                       // not used in fhtsupwesite
    updateSqlScript: "AddSqlScript",                        // not used in fhtsupwesite
    releaseNewVersion: "ReleaseNewVersion",                 // not used in fhtsupwesite
    deleteSite: "DeleteSite",                               // not used in fhtsupwesite
    deleteAccount: "deleteAccount",                         // not used in fhtsupwesite
    deleteInstallSoftware: "DeleteInstallSoftware",         // not used in fhtsupwesite
    deleteSoftware: "DeleteSoftware",                       // not used in fhtsupwesite
    deleteSoftwareConfig: "DeleteSoftwareConfig",           // not used in fhtsupwesite
    deleteReleaseVersion: "DeleteReleaseVersion",           // not used in fhtsupwesite
    deleteUninstall: "DeleteUninstall",                     // not used in fhtsupwesite
    deleteMachine: "DeleteRegisterMachine",                 // not used in fhtsupwesite
    //for v.1
    getHeartbeat: "GetHeartbeat",
    getPocStatus: "GetPocStatus",
    getMachineOnSite: "GetMachineOnSite",
    getMachineOnSiteV2: "GetMachineOnSiteV2",
    getSystemMapping: "GetSystemMapping",
    getLocalVersion: "GetLocalVersion",
    getSite: "GetSite",
    getSiteIDsWithMariaDB: "GetSiteIDsWithMariaDB",
    getSiteRegister: "GetSiteRegister",
    getSystem: "GetSystem",
    getLasteRelease: "GetLasteRelease",
    getFhtLog: "GetFhtLog",
    getFhtLogV2: "GetFhtLogV2",
    getLatestAlgorithmHistory: "GetLatestAlgorithmHistory",
    getSiteAlgorithmHistory : "GetSiteAlgorithmHistory",
    releaseUpdateVersion: "ReleaseUpdateVersion",
    releaseSqlScript: "ReleaseSqlScript",
    updateFhtSite: "UpdateFhtSite",
    updateSystemMapping: "UpdateSystemMapping",
    updateFhtSystem: "UpdateFhtSystem",
    deleteFhtSite: "DeleteFhtSite",
    deleteSystemMapping: "DeleteSystemMapping",
    deleteFhtSystem: "DeleteFhtSystem",
    deleteMachineOnSite: "DeleteMachineOnSite",
    releaseAutoupdater: "ReleaseAutoupdater",
    //common
    getUserInformation : "GetUserInformation",
    getRecommendationQuery: "GetRecommendationQuery",
    updateRecommendationQuery: "UpdateRecommendationQueries",
    getTriggerDescription: "GetTriggerDescription",               // not used in fhtsupwesite
    userRegistration: "registerUserInAws",
    generateInstallCommand: "GenerateInstallCommand",
    resetAccountPassword: "ResetAccountPassword",
    changePassword : "ChangePassword",
    editUsers : "EditUsers",
    getNewMachines: "GetNewMachines",
    getAccounts: "GetAccounts",
    getAutoupdaterHeartbeat: "GetAutoupdaterHeartbeat",
    getRecommendationDescription:"GetRecommendationDescription", // not used in fhtsupwesite
    saveNewSiteGroupings: "SaveNewSiteGroupings",
    getCurrentSiteGroupings: "GetCurrentSiteGroupings",
    updateSiteGroupings: "UpdateSiteGroupings",
    displayPocStatus:"PocStatusDisplay",                        // not used in fhtsupwesite
    getAutoupdateMachineLog: "GetFhtMachineAutoupdateLogs",     // not used in fhtsupwesite
    getMachinePocLog: "GetFhtMachinePocLogs",
    serverSummaryHistory:"getLogServerSummary",
    getServerSummary:"getServerSummary",
    getAPISummary:"GetAPISummary", // May be deprecated soon along with APISummary.jsx
    getSystemSummary : "GetSystemSummary",
    getSystemSummaryAll : "GetSystemSummaryAll",
    getEMRDBMachine:"EMRDBDisplay",                             // not used in fhtsupwesite
    getSitedetails: "GetFhtSiteDetails",                        // not used in fhtsupwesite
    deleteFhtSitedetails:"DeleteFhtSitedetails",
    updateFhtSitedetails: "UpdateFhtSitedetails",
    getFhtSitesummary1: "GetFhtSitesummaryrel",
    getFhtSitesummary2: "GetFhtSitesummarytech",
    updateFhtSitesummary1: "UpdateFhtSitesummaryrel",
    updateFhtSitesummary2: "UpdateFhtSitesummarytech",
    getFHTAlgorithmHistory: "getFHTAlgorithmHistory",
    getUserAccountRoles: "getUserAccountRoles",
    updateAccountRoles: "updateAccountRoles",
    getFHTSupportAccountRole: "GetFHTSupportAccountRole",
    getAlgorithmStatus: "getAlgorithmStatus",                 // not used in fhtsupwesite
    getFhtConfigModuleAndActiveModuleMap: "getFhtConfigModuleAndActiveModuleMap",
    updateMultipleSiteModuleConfigs: "updateMultipleSiteModuleConfigs",
    queryFHTLogsFilterSearch: "queryFHTLogsFilterSearch",
    getFhtLogPageFilterQuery: "GetFhtLogPageFilterQuery",
    getActivePOCModulesAndPOCModules: "getActivePOCModulesAndPOCModules",
    updatePOCModules: "updatePOCModules",
    getNumPOCDeactivatedSites : "GetNumPOCDeactivatedSites", // FHTHome
    getAWSTorchLoggingCohortStatusHistory: "GetAWSTorchLoggingCohortStatusHistory",
    getAWSTorchLoggingTrialStatusSiteSummaryCounts: "GetAWSTorchLoggingTrialStatusSiteSummaryCounts",
    getAWSTorchLoggingTrialStatusCohortSummaryCounts: "GetAWSTorchLoggingTrialStatusCohortSummaryCounts",
    getConfigDisplayAlgoMap: "GetConfigDisplayAlgoMap",
    postSiteDisplayAlgoMappingUpdate: "PostSiteDisplayAlgoMappingUpdate",
    getActiveDisplayAlgorithmsForSites: "GetActiveDisplayAlgorithmsForSites",
    getSiteActiveDisplayAlgorithms: "GetSiteActiveDisplayAlgorithms",
    cleanAWSLoggingCohortRecommendationTable: "CleanAWSLoggingCohortRecommendationTable",
    getStaffExtractCountsData: "GetStaffExtractCountsData",
    getSummaryWebAPIData: "GetSummaryWebAPIData",
    getSummaryALGData: "GetSummaryALGData",
    getSiteSummaryData: "GetSiteSummaryData",
    getConfigEditSiteList: "GetConfigEditSiteList",
    getConfigEditAlgoMappingList: "GetConfigEditAlgoMappingList",
    getAlgorithmDescriptions: "GetAlgorithmDescriptions",
    updateRemoteCohortCreationConfig: "UpdateRemoteCohortCreationConfig",
    getSitesAndActiveCohortNames: "GetSitesAndActiveCohortNames",
    getSitesAndRemoteCohortCreationsInConfig: "GetSitesAndRemoteCohortCreationsInConfig",
    removeAllRemoteConfigsAllSites: "RemoveAllRemoteConfigsAllSites",
    removeSelectedRemoteConfigsSelectedSites: "RemoveSelectedRemoteConfigsSelectedSites",
    getRemoteCreateCohortHistory: "GetRemoteCreateCohortHistory",
    encryptAllSiteAlgConfigs: "EncryptAllSiteAlgConfigs",
    encryptString: "EncryptString",
    decryptString: "DecryptString",
    saveAllCohortHistoricalStatus: "SaveAllCohortHistoricalStatus",
    deleteSiteGrouping: "DeleteSiteGrouping",
    getALGAPISiteOverviewHistoricalGraphData: "GetALGAPISiteOverviewHistoricalGraphData",
    getSitesAndMachineStatus: "GetSitesAndMachineStatus",
    getMachinesOnSiteCurrentSoftwareStatus: "GetMachinesOnSiteCurrentSoftwareStatus",
    getSoftwareIDToSoftwareName: "GetSoftwareIDToSoftwareName",
    uninstallSelectedSoftwareMachine: "UninstallSelectedSoftwareMachine",
    deactivateSelectedMachine: "DeactivateSelectedMachine",
    deactivateSelectedSite: "DeactivateSelectedSite",
    uninstallAutoupdatersSelectedSite: "UninstallAutoupdatersSelectedSite",
    getActiveAlgorithmTableData: "GetActiveAlgorithmTableData",
    getSiteAlgorithmStatusTableData: "GetSiteAlgorithmStatusTableData",
    getAlgorithmOrderList: "GetAlgorithmOrderList",
    updateAlgorithmStatusTableConfigs: "UpdateAlgorithmStatusTableConfigs",
    updateAlgorithmOrder: "UpdateAlgorithmOrder",
    getLatestAlgorithmHistoryHourFiltering: "GetLatestAlgorithmHistoryHourFiltering",
    getAPISummaryHourFiltering: "GetAPISummaryHourFiltering",
    getAlgorithmClassNamesData: "GetAlgorithmClassNamesData",
    updateAlgorithmClassName: "UpdateAlgorithmClassName",
    insertNewAlgorithmClassName: "InsertNewAlgorithmClassName",
    deleteAlgorithmClassName: "DeleteAlgorithmClassName",
  },

  snackbarVariants: {
    success: "success",
    warning: "warning",
    error: "error",
    info: "info",
  },
  snackbarMessages: {
    updateSuccess: "Update success",
    requestUninstallDetails: "Please select target machine and software",
    requestUninstallSuccess: "Successfully request uninstall software",
    requestUninstallFail: "Failed request uninstall software",
    requestStartServiceSuccess: "Successfully request start service",
    requestStartServiceFail: "Failed request start service",
    requestRevokeUninstallSuccess: "Successfully remove uninstall record",
    requestRevokeUninstallFail: "Failed to remove uninstall record",
    addSqlScriptSuccess: "Successfully add new Sql Script",
    addSqlScriptFail: "Failed add new Sql Script",
    releaseSuccess: "successfully released to selected site/s",
    updateFail: "Update failed",
    accountIdType: "Account Id need to be number",
    deleteSuccess: "Delete success",
    deleteInUsedFail: "is in used and cannot be deleted",
    deleteFail: "Delete failed",
    siteDetails: "Please complete site detail",
    systemDetails: "Please enter software name",
    softwareDetails: "Please complete software details",
    configDetails: "Please complete software config details",
    accountNumberExist: "Account number has been taken",
    accountNumberFormat: "Account number need to be integer",
    accountDetails: "Please complete account details",
    softwareMapping: "Please enter all software mapping details",
    softwareUpdate: "Software has been mapped for this account id",
    duplicateSoftwareConfig: "The configuration has been set for this account and software id",
    deleteConfigFail: "Cannot delete selected record",
    releaseFail: "Cannot release new version",
    releaseSqlFail: "Cannot release sql file",
    duplicateSoftware: "Software already exist",
    duplicateAccountId: "This account id has been taken",
    duplicateSystemMapping: "has been registered on",
    duplicateReleaseDetails: "Record is already exist, please check and update the record",
    releaseDetails: "Please complete release details",
    userRegistrationSuccess: "Successfully register new user",
    userRegistrationFail: "Failed to register new user",
    commandGenerateDetails: "Please enter fhtuser and fhtpwd",
    updatePasswordDetails: "Please enter all required field", 
    userRegistrationDetails: "Please complete user registration details",
    mappingDetails: "Please select software, site, and machines to map/unmap",
    successfulCreateNewSiteGroup: "Successfully created new site grouping",
    failedCreateNewSiteGroup: "Failed to created new site grouping",
    unauthorised: "Account unauthorised to access this action.",
    duplicateSiteGroupingName: "Duplicate site grouping name.",
  },
  // TODO: Change DEV_Mode to true before releasing
  DEV_Mode: false,

  // TODO: Change dateTimeInUse to Date.now() before releasing
  // dateTimeInUse: Date.parse("2020-11-24 02:23:55"),
  // Need to remove the .000z format at end of ISOString.
  // Date.parse produced inaccurate results from UTCNow (ISOString) to our FHT Dates (non-isostring)
  dateTimeInUse: Date.parse(new Date().toISOString().split(".")[0]),
  BETA_FEATURE: true,

  configurationEditingPageTypes: {
    accountChkAlgConfig: "accountChkAlgConfig",
    accountRecAlgConfig: "accountRecAlgConfig",
    accountDisplayAlgConfig: "accountDisplayAlgConfig",
    pocModuleConfig: "pocModuleConfig",
    siteModulesConfig: "siteModulesConfig",
    remoteCreateCohorts: "remoteCreateCohorts",
    createCohortMonitoring: "createCohortMonitoring",
  },
  RecOrChkSaveType: {
    Chk: 1,
    Rec: 2,
    DisplayRec: 3,
  },
  RemoteCohortCreationTypes: {
    CreateCohort: 1,
    RecreateCohort: 2,
  },
  SoftwareStatusIDs: {
    0: {currentStatus: "Unsuccessful", statusColour: "red"},
    1: {currentStatus: "Successful", statusColour: "green"},
    2: {currentStatus: "Uninstalling", statusColour: "orange"},
    3: {currentStatus: "No request", statusColour: "black"},
  },
  AlgorithmTableUpdateAllSites: true,
  algorithmStatusTableSearch: {
    recommendationSearch: "recommendationAlgorithms",
    checkingSearch: "checkingAlgorithms",
  },
  AlgorithmTableStatus: {
    updateAllAlg: 0,
    live: 1,
    silent: 2,
    off: 3,
  },
  RemoteCohortMonitoringActionType: {
    noAction: 0,
    selectSitesSelectCohortsConfig: 1,
    allSitesAllCohortsConfig: 2,
  },
  ShutdownLogEventType: {
    shutdown: 0,
    serviceStopped: 1,
    suspend: 2,
  },
  MachineTypeID: {
    normal: 0,
    server: 1,
  },
  RecommendationTypeID: {
    recommendation: 1,
    check: 3,
  },

  // Eventually move this to the back-end and remove from ConfigDisplay/RecChks pages too.
  DiseaseIDToName: {
    0: "Uncategorised",
    1: "Chronic Kidney Disease",
    2: "Cardiovascular Disease",
    3: "Diabetes",
    4: "Prostate Cancer",
    5: "Platelet Cancer",
    6: "Gastrointestinal/Endometrial Cancer",
    10: "Training module: Diabetes",
    15: "UQ Release",
    19: "HARMON-E",
    20: "Hepatitis B(Simply B)",
    21: "Actmed",
    22: "Lung Cancer",
    23: "Unexpected weight loss(Canary)",
    25: "IDEAL CARE",
    26: "Lower back pain",
    27: "Good for mums",
    28: "Western health zeus trial",
    29: "Boehringer Synchronize trial",
    30: "Novonordisk NN9541",
    31: "Astrazeneca Tharros",
    32: "Pancreatic Cancer",
    33: "Astrazeneca CIN107",
    34: "MenoPrompt",
    35: "Mineralys HTN",
    50: "DirectT1DM"
  },
  AlgorithmStatusIDs: {
    0: "Archived",
    1: 'Active'
  },
  FhtServiceNames: [
    "fhtautoupdater",
    "fhtmysql",
    "fhtwebapi"
  ],  
};

export default Configs;
