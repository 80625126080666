// This file stores the common functions, namely the functions shared across different components
// Status: Ready

import Configs from "../Service/Configuration";

const dateIU = Configs.dateTimeInUse;

export const hoursToMilliseconds = (h) => {
  return h * 60 * 60 * 1000;
};

export const countPCs = (dict) => {
  let info = {};
  Object.keys(dict).forEach((siteID) => {
    info[siteID] = dict[siteID].size;
  });
  return info;
};

export const countValues = (values) => {
  let count = 0;
  values.forEach((value) => {
    if (value.size > 0) {
      count = count + 1;
    }
  });
  return count;
};

export const verifyWeekly = (timeIn) => {
  return dateIU - Date.parse(timeIn) < hoursToMilliseconds(170);
};

export const verifyDaily = (timeIn) => {
  return dateIU - Date.parse(timeIn) < hoursToMilliseconds(26);
};

export const verifyHours = (timeIn, targetHours) => {
  return dateIU - Date.parse(timeIn) < hoursToMilliseconds(targetHours);
};

export const convertALGDataToCorrectCSV = (header, data, isHistory) => {
  console.log("header", header)
  console.log("data", data)

  // Need to convert the ALGHistory data to have the correct testID that match table header.
  if (isHistory == true) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].tests != null) {
        for (let [key, value] of Object.entries(data[i].tests)) {
          //Filter for values containing the below data:
          //<p key={value} style={{ background: "palegreen", display: "inline", padding: "2.5%" }}>{value}</p>
          if (value.includes("<p key={")){
            const leftCurlyBracesSplit = value.split("{");
            const rightCurlyBracesSplit = leftCurlyBracesSplit[1].split("}");
            data[i]["tests[" + key + "]"] = rightCurlyBracesSplit[0];
          }
          else
            data[i]["tests[" + key + "]"] = value
        }
      }
    }
  }

  var allHeaderTitles = []
  var allHeaderFields = []

  var dataToExport = []
  for (var i = 0; i < header.length; i++) {
    if (header[i].title == "Logs")
      continue
    allHeaderTitles.push(header[i].title)
    allHeaderFields.push(header[i].field)
  }

  for (var i = 0; i < data.length; i++) {
    var relevantDataToExport = {}
    for (var j = 0; j < allHeaderFields.length; j++) {
      var eachHeaderField = allHeaderFields[j]
      var dictionaryFieldHeader = header.filter(a => a.field == allHeaderFields[j])[0].title
      if (data[i][eachHeaderField] == undefined) {
        relevantDataToExport[dictionaryFieldHeader] = ""
        continue
      }
      if (typeof data[i][eachHeaderField] == "object") {
        var parsedTotalNumber = data[i][eachHeaderField].key
        relevantDataToExport[dictionaryFieldHeader] = parsedTotalNumber
        continue
      }
      if (typeof data[i][eachHeaderField] == "number") {
        relevantDataToExport[dictionaryFieldHeader] = String(data[i][eachHeaderField])
        continue
      }
      // Only ALGSummaryHistory needs to be converted
      if (isHistory == true && eachHeaderField == "fhtLogDateLogReceived") {
        relevantDataToExport[dictionaryFieldHeader] = utcToAEST(data[i][eachHeaderField])
        continue
      }
      relevantDataToExport[dictionaryFieldHeader] = data[i][eachHeaderField]
    }
    dataToExport.push(relevantDataToExport)
  }
  return dataToExport
};

export const manageVersions = (fetched) => {
  let machineVers = {};
  let siteVers = {};
  let webAPISiteVers = {};

  fetched.forEach((machineVer) => {
    // MachineID and WebAPI version number
    if (machineVer.fhtLocalVersionSystemId == 2) {
      machineVers[machineVer["fhtLocalVersionMachineId"]] =
        machineVer["fhtLocalVersionCurrentVersion"];
    }

    // SiteID and WebAPI version number
    if (machineVer.fhtLocalVersionSystemId == 2) {
      webAPISiteVers[machineVer["fhtLocalVersionSiteId"]] =
        machineVer["fhtLocalVersionCurrentVersion"];
    }

    // SiteID and Algorithm version number
    if (machineVer.fhtLocalVersionSystemId == 1) {
      siteVers[machineVer["fhtLocalVersionSiteId"]] =
        machineVer["fhtLocalVersionCurrentVersion"];
    }
  });

  return { machineVers: machineVers, siteVers: siteVers, webAPISiteVers: webAPISiteVers };
};

export const removeArrayDuplicates = (preFetched) => {
  let fetched = [];
  let siteIDs = new Set();
  preFetched.forEach((site) => {
    if (!siteIDs.has(site["siteID"])) {
      fetched.push(site);
    }
    siteIDs.add(site["siteID"]);
  });
  return fetched;
};

export const removeLogDuplicates = (preFetched) => {
  let fetched = [];
  let siteIDs = new Set();
  preFetched.forEach((site) => {
    if (!siteIDs.has(site["fhtLogSiteId"])) {
      fetched.push(site);
    }
    siteIDs.add(site["fhtLogSiteId"]);
  });
  return fetched;
};
// Removing duplicated sites, filtering according to the given param
export const preFiltering = (preFetched, filterValue) => {
  let fetched = [];
  let siteIDs = new Set();
  preFetched.forEach((site) => {
    if (!siteIDs.has(site["siteID"])) {
      if (filterValue === undefined) {
        fetched.push(site);
      } else if (filterValue === site["fhtHeartbeatVersionNumber"]) {
        fetched.push(site);
      } else if (filterValue === "active" || filterValue === "fail") {
        fetched.push(site);
      }
    }
    siteIDs.add(site["siteID"]);
  });
  return fetched;
};

// Convert to UTC time(e.g from DB) to local time(e.g AEST/AEDT)
export const utcToLocal = (utcTime) => {
  try {
    if (!utcTime.includes("Z")) {
      utcTime = utcTime.toString() + "Z";
    }
    let outTime = new Date(utcTime);
    outTime = outTime.toLocaleDateString('en-GB') + ", " + outTime.toLocaleTimeString();
    return outTime;
  } catch {
    return "No last successful run";
  }
};

export const utctoAEDT = (utcTime) => {
  if (!utcTime.includes("Z")) {
    utcTime = utcTime.toString() + "Z";
  }
  return utcTime;
}

export const utcToAusLocal = (utcTime) => {
  if (utcTime !== null) {
    if (!utcTime.includes("Z")) {
      utcTime = utcTime.toString() + "Z";
    }
  }
  else {
    return null;
  }
  let outTime = new Date(utcTime);
  return outTime
};

export const utcToAESTLocal = (utcTime) => {
  if (utcTime !== null) {
    if (!utcTime.includes("Z")) {
      utcTime = utcTime.toString() + "Z";
    }
  }
  else {
    return null;
  }
  let outTime = new Date(utcTime);
  outTime = outTime.toLocaleDateString('en-GB') + ", " + outTime.toLocaleTimeString();
  return outTime
};

//Convert to AEST
export const utcToAEST = (utcTime) => {
  if (utcTime !== undefined) {
    if (!utcTime.includes("Z")) {
      utcTime = utcTime.toString() + "Z";
    }
  }
  else {
    return null;
  }
  let outTime = new Date(utcTime);
  outTime = outTime.toLocaleDateString('en-GB') + ", " + outTime.toLocaleTimeString();
  return outTime;
};

export const formatDate = (utcTime) => {
  utcTime = utcTime.toString() + "Z";
  let localTime = new Date(utcTime);
  let formatDate = `${localTime.getDate()}/${localTime.getMonth()}/${localTime.getFullYear()}`;
  return formatDate;
}

export const checkDaily = (timeIn) => {
  let localdatetime = new Date();
  let localdate = localdatetime.getDate();
  timeIn = timeIn.toString() + "Z";
  let pocdatetime = new Date(timeIn);
  let pocdate = pocdatetime.getDate();
  return localdate === pocdate;
}

export const formateDateTime = (utcTime) => {
  if (!utcTime.includes("Z")) {
    utcTime = utcTime.toString() + "Z";
  }
  let outTime = new Date(utcTime);
  outTime = `${outTime.getDate()}/${outTime.getMonth()}/${outTime.getFullYear()} - ${outTime.toLocaleTimeString()}`;
  return outTime;
};

export const compareDateTimes = (a, b) => {
  var convertedDateA = new Date(a);
  var convertedDateB = new Date(b);
  return convertedDateA.getTime() - convertedDateB.getTime()
};

export const changeGraphDataDateToAEST = (graphData) => {
  var reformattedGraphData = []
  try {
    for (var i = 0; i < graphData.length; i++) {
      try {
        var formattedDate = utcToAESTLocal(graphData[i].Date).split(",")[0]
        reformattedGraphData.push({
          "Date": formattedDate,
          "Active Sites": graphData[i]["Active Sites"],
          "Inactive Site": graphData[i]["Inactive Site"],
        })
      } catch { }
    }
    return reformattedGraphData;
  } catch {
    return reformattedGraphData;
  }
};

export const backgroundColourFromLetter = (capitalLetter) => {
  switch (capitalLetter) {
    case "A":
      return "firebrick"
    case "B":
      return "greenyellow"
    case "C":
      return "lightsalmon"
    case "D":
      return "lightpink"
    case "E":
      return "lightseagreen"
    case "F":
      return "mediumorchid"
    case "G":
      return "mediumpurple"
    case "H":
      return "mediumturquoise"
    case "I":
      return "mediumspringgreen"
    case "J":
      return "orange"
    case "K":
      return "orangered"
    case "L":
      return "palevioletred"
    case "M":
      return "saddlebrown"
    case "N":
      return "slateblue"
    case "O":
      return "skyblue"
    case "P":
      return "tomato"
    case "Q":
      return "thistle"
    case "R":
      return "tan"
    case "S":
      return "teal"
    case "T":
      return "lightblue"
    case "U":
      return "khaki"
    case "V":
      return "hotpink"
    case "W":
      return "darkred"
    case "X":
      return "darkgreen"
    case "Y":
      return "darkblue"
    case "Z":
      return "darkgoldenrod"
    default:
      break;

  }
};
