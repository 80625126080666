// Description: This component pops up after clicking any site from the main page
// Status: Ready

import React from "react";
import Configs from "../Service/Configuration";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PeopleIcon from "@material-ui/icons/People";
import BusinessIcon from "@material-ui/icons/Business";
import Divider from '@material-ui/core/Divider';
import FetchApi from "../Service/FetchApi";

import { Link } from "react-router-dom";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import GroupworkIcon from "@material-ui/icons/GroupWork";
import SettingsIcon from "@material-ui/icons/Settings";
import CloudIcon from "@material-ui/icons/Cloud";
import LinkIcon from "@material-ui/icons/Link";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import EmailIcon from "@material-ui/icons/Email";
import PersonIcon from "@material-ui/icons/Person";
import { getHeartbeats, getPOCversions, getMachines } from "./FetchFunctions";
import { utcToLocal, utcToAEST, removeLogDuplicates, verifyDaily, verifyWeekly, checkDaily } from "./HelperFunctions";
import { Checkbox } from "@material-ui/core";
import ComputerIcon from '@material-ui/icons/Computer';
import StorageIcon from "@material-ui/icons/Storage";
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { Grid } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

const styles2 = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles2)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const SiteLink = withStyles(styles2)((props) => {
  if (props.status === "active") {
    return (
      <>
        <FiberManualRecordIcon
          style={{
            fill: "green",
          }}
        />
        {props.type}
      </>
    );
  } else if (props.status === "fail") {
    return (
      <>
        <FiberManualRecordIcon
          style={{
            fill: "red",
          }} />
        {props.type}
      </>
    );
  }
  return null;
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const setToString = (elements) => {
  let outString = " ";
  elements.forEach((e) => {
    outString += e + ", ";
  });
  outString = outString.slice(0, -2);

  return outString;
};

const styles = {
  greenFill: {
    fill: "green",
  },
  redFill: {
    fill: "red",
  },
};


export default function SiteDetailDialog(props) {
  const siteInfo = props.siteInfo;
  const siteType = props.type;
  const [supPost, setsupPost] = React.useState('');
  const [siteContacts, setSiteContacts] = React.useState(null);
  const [serverMachineName, setServerMachineName] = React.useState(null);
  const [siteEMR, setSiteEMR] = React.useState(null);
  const [siteURL, setSiteURL] = React.useState(null);
  const [techsupPost, settechsupPost] = React.useState('');
  const [supMes, setsupMes] = React.useState('');
  const [renderSnackbar, setSnackbar] = React.useState(false);
  const [snackbarMessageColor, setSnackbarMessageColor] = React.useState("green");

  const [techMes, settechMes] = React.useState('');
  const [newMachCount, setnewMachCount] = React.useState(0);
  const [machSitecount, setmachSitecount] = React.useState(0);
  const [logDate, setlogDate] = React.useState('N/A');
  const [statusLog, setstatus] = React.useState('N/A');
  const [userTot, setuserTot] = React.useState(0);
  const [dayTot, setdayTot] = React.useState(0);
  const [weekTot, setweekTot] = React.useState(0);
  const [pocVer, setpocVer] = React.useState('');

  React.useEffect(() => {
    fetchRelSummary();
    fetchTechSummary();
    fetchSiteContacts();
    getNewMachine();
    getMachineSite();
    getLatestlog();
    sitepocTotals();
    localVersion();
  }, [])

  //Counting new machines
  const getNewMachine = async () => {
    const api = FetchApi(Configs.api.getNewMachines);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then((response) => {
      response.map(r => {
        r.convertDate = utcToLocal(r.fhtMachineOnSiteRegisterDate);
      })
      const newMach1 = [...response];
      setnewCount(newMach1);
    });
  }

  const setnewCount = (newMach) => {
    let newCount = 0;
    newMach.map(r => {
      if (r.fhtMachineOnSiteFhtSiteId === siteInfo["siteID"]) {
        newCount = newCount + 1;
        setnewMachCount(newCount);
      }
    });
  }

  //counting machines on site
  const getMachineSite = async () => {
    const api = FetchApi(Configs.api.getMachineOnSite);
    await api.get().then((response) => {
      const newMach1 = [...response];
      setMachineCount(newMach1);
    });
  }

  const setMachineCount = (newMach) => {
    let newCount = 0;
    newMach.map(r => {
      if (r.fhtMachineOnSiteFhtSiteId === siteInfo["siteID"]) {
        newCount = newCount + 1;
        setmachSitecount(newCount);
      }
    });
  }

  //Latest FHT Logs
  const getLatestlog = async () => {
    const api = FetchApi(Configs.api.getFhtLog);
    await api.get().then((response) => {
      response = response.sort((a, b) => (a.fhtLogDateLogReceived < b.fhtLogDateLogReceived) ? 1 : -1)
      response.map(r => {
        r.convertLogDate = utcToLocal(r.fhtLogDateLogReceived);
      })
      const fhtLog = [...response];
      const latestLog = removeLogDuplicates(fhtLog);
      setLatestLog(latestLog)
    });
  }

  const setLatestLog = (log) => {
    let logDate = null;
    let status1 = null;
    log.map(r => {
      if (r.fhtLogSiteId === siteInfo["siteID"]) {
        logDate = r.convertLogDate;
        status1 = r.fhtLogProcessSuccessful;
        setlogDate(logDate);
        setstatus(status1);
      }
    })
  }

  //Adding user and popup totals
  const sitepocTotals = async () => {
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    var sitePOCSummaryCounts = [];
    var api = FetchApi(Configs.api.getSystemSummary + "/FHTPOC"); //FHTPOC
    await api.post(body).then(response => {
      sitePOCSummaryCounts = response;
      console.log("sitepocTotals FHTPOC", response);
    })
    // END: Collect site metaData
    setpocTotals(sitePOCSummaryCounts);
  }
  const setpocTotals = (sitetable) => {
    let userTotal = null;
    let dayPopup = null;
    let weekPopup = null;

    // Naming convention of Item1, Item2 is "legacy code".
    // SummarizePopups in WebAPI details which items are which.
    sitetable.map(r => {
      if (r.siteId === siteInfo["siteID"]) {
        userTotal = r.uniqueUsers;
        setuserTot(userTotal);
        dayPopup = r.popups.Item1;
        setdayTot(dayPopup);
        weekPopup = r.popups.Item2;
        setweekTot(weekPopup);
      }
    })
  }

  //POC version number addition
  const localVersion = async () => {
    const pocVersion = await getPOCversions();
    const getmachines = await getMachines();
    var machinepoc = getmachines.map(function (e) {
      return Object.assign({}, e, pocVersion.reduce(function (ma, po) {
        if (po.fhtLocalVersionMachineId === e.fhtMachineOnSiteId) {
          return po
        } else {
          return ma
        }
      }, {}))
    });
    console.log(machinepoc);
    let pocVer_filter = machinepoc.filter(vr => vr.fhtLocalVersionSiteId === siteInfo["siteID"]);
    const vernumbersPOC = [...new Set(pocVer_filter.map(x => x.fhtLocalVersionCurrentVersion))];
    console.log(vernumbersPOC);
    const verNumbertext = [...vernumbersPOC].join(",");
    setpocVer(verNumbertext)
  }

  //Handling relationship support summary textbox
  const fetchRelSummary = async () => {
    const api = FetchApi(`${Configs.api.getFhtSitesummary1}/${siteInfo["siteID"]}`);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    await api.post(body).then((response) => {
      console.log("Rel", response);
      setsupPost(response);
    });
  }

  //xx
  const fetchSiteContacts = async () => {
    console.log("Fetching contacts");
    const api = FetchApi("GetSiteDetail/" + siteInfo["siteID"]);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    await api.post(body).then((response) => {
      console.log("fetchSiteContacts Contacts", response);
      setSiteContacts(response.siteDetails);
      setSiteEMR(response.emrSystem);
      setSiteURL(response.fhtURL);
      setServerMachineName(response.serverMachineName);
    }).catch(err => {
      console.log("Contact not found", err);
    });
  }

  const handleRelChange = (event) => {
    setsupPost(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = {
      fhtSiteId: siteInfo["siteID"],
      fhtSiteRelSummary: supPost,
      fhtSiteName: 'test',
      fhtSiteState: 'test',
      fhtAccountId: 1,
      fhtSiteTerminalServer: 1,
      fhtSiteTechSummary: 'test',
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    }
    console.log(data);
    const api = FetchApi(Configs.api.updateFhtSitesummary1);
    api.post(data)
      .then((response) => {
        setsupMes('Update success')
        setSnackbar(true);
        setSnackbarMessageColor("green");
      })
      .catch((err) => {
        setsupMes('Update failed')
        setSnackbar(true);
        setSnackbarMessageColor("red");
      });
  }
  //Handling tech support summary textbox
  const fetchTechSummary = async () => {
    const api = FetchApi(`${Configs.api.getFhtSitesummary2}/${siteInfo["siteID"]}`);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    await api.post(body).then((response) => {
      console.log("fhtSiteTechSummary", response);
      settechsupPost(response);
    });
  }

  const handleTechChange = (event) => {
    settechsupPost(event.target.value)
  }

  const handleTechSubmit = (event) => {
    event.preventDefault();
    let data = {
      fhtSiteId: siteInfo["siteID"],
      fhtSiteTechSummary: techsupPost,
      fhtSiteRelSummary: 'test',
      fhtSiteName: 'test',
      fhtSiteState: 'test',
      fhtAccountId: 1,
      fhtSiteTerminalServer: 1,
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    }
    console.log(data);
    const api = FetchApi(Configs.api.updateFhtSitesummary2);
    api.post(data)
      .then((response) => {
        settechMes('Update success')
        setSnackbar(true);
        setSnackbarMessageColor("green");
      })
      .catch((err) => {
        settechMes('Update failed')
        setSnackbar(true);
        setSnackbarMessageColor("red");
      });
  }

  // TBD: This is just a precaution for deprecated data, can be deleted if the database is integrated
  if (siteInfo["ALGTestIDs"] === undefined) {
    siteInfo["ALGTestIDs"] = [];
  }

  return (
    <div>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.isOpen}
        fullWidth={true}
        maxWidth={"xl"}
      >
        {SiteMetaData()}
        {siteType === "FHT" ? FHTContent() : null}
        {siteType === "SC4C" ? SC4CContent() : null}
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  function SC4CContent() {
    return (
      <DialogContent dividers>
        {siteInfo["SC4C"] === "NA" ? null : (
          <>
            <Link
              className="nsLink"
              to={{
                pathname: "/SC4CSummary",
                state: {
                  Keyword: siteInfo["siteName"],
                },
              }}
            >
              <SiteLink type="SC4C" status={siteInfo["SC4C"]} />
              <br />
              Version(s): {setToString(siteInfo["SC4CVers"])}
              <br />
              Last Extracted: {utcToLocal(siteInfo["SC4CLastLog"])}
              <br />
            </Link>
          </>
        )}
      </DialogContent>
    );
  }

  function renderShutdownLogHistory() {
    return (
      <div style={{ width: "95%", height: "35vh", overflow: "auto" }}>
        <Table aria-label="simple table" style={{ width: "95%" }}>
          <TableHead>
            <TableRow>
              <TableCell align="left">PC Name</TableCell>
              <TableCell align="left">Log Date</TableCell>
              <TableCell align="right">Shutdown Type</TableCell>
              <TableCell align="right">Server</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {siteInfo.shutdownLogHistory.map((shutdownData, index) => (
              <TableRow key={index}>
                <TableCell align="left">{shutdownData.shutdownLogPCName}</TableCell>
                <TableCell align="left">{shutdownData.shutdownLogDate}</TableCell>
                <TableCell align="center" >{renderShutdownEventType(shutdownData.shutdownLogEventType)}</TableCell>
                <TableCell align="center" style={{ width: "3REM" }}>{renderServerType(shutdownData.shutdownLogPCServer)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    )
  }
  function renderServerType(shutdownLogPCServer) {
    var cellBackgroundColor = "";
    var eventTypeText = "";
    if (shutdownLogPCServer == Configs.MachineTypeID.server) {
      cellBackgroundColor = "green";
      eventTypeText = "Yes";
    }
    return (
      <div style={{ backgroundColor: cellBackgroundColor, color: "white" }}>
        {eventTypeText}
      </div>
    )
  }

  function renderShutdownEventType(shutdownLogEventType) {
    var cellBackgroundColor = "#FF0008FF";
    var eventTypeText = "";
    if (shutdownLogEventType == Configs.ShutdownLogEventType.shutdown) {
      cellBackgroundColor = "#FF0008FF";
      eventTypeText = "Shutdown";
    }
    if (shutdownLogEventType == Configs.ShutdownLogEventType.serviceStopped) {
      cellBackgroundColor = "#FF8C00FF";
      eventTypeText = "Service stopped";
    }
    if (shutdownLogEventType == Configs.ShutdownLogEventType.suspend) {
      cellBackgroundColor = "#D4BB00FF";
      eventTypeText = "Suspended";
    }
    return (
      <div style={{ backgroundColor: cellBackgroundColor, color: "white" }}>
        {eventTypeText}
      </div>
    )
  }

  function FHTContent() {
    console.log("FHTContent siteInfo.shutdownLogHistory", siteInfo.shutdownLogHistory);

    return (
      <DialogContent dividers>
        {siteInfo["POC"] === "NA" ||
          (Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchSupport ||
            Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchTestSupport) ? null : (
          <>
            <Link
              className="nsLink"
              to={{
                pathname: "/POCSummary",
                state: {
                  Keyword: siteInfo["siteName"],
                },
              }}
            >
              <SiteLink type="POC" status={siteInfo["POC"]} />
              {siteInfo["siteIsTerminal"]
                ? "(Terminal Server)"
                : "(Local Server)"}
              {(siteInfo["POCActiveTodayNum"] / siteInfo["POCTotalNum"] * 100).toFixed(0)}
              {"%"}
              <br />
              POC Heartbeats:
              <br />
              Today: {siteInfo["POCActiveTodayNum"]}(Connected),
              {siteInfo["POCTotalNum"] - siteInfo["POCActiveTodayNum"]}(Not connected)
              <br />
              Inactive last connection: {siteInfo["POCActiveThisWeekNum"] - siteInfo["POCActiveTodayNum"]}({'<'} 7 days),
              {siteInfo["POCTotalNum"] - siteInfo["POCActiveThisWeekNum"]}({'>'} 7 days)
              <br />
              {/* 
              POC active: {siteInfo["POCActiveNum"]}
              {" | "}
              POC installed: {siteInfo["POCTotalNum"]}
              <br />
              */}
              POC Version(s): {pocVer}
              <br />
              Last log: {utcToLocal(siteInfo["POCLastLog"])}
              <br />
              <PeopleIcon style={{ fill: "blue", }} />
              Total users: {userTot}
              <br />
              <PeopleIcon style={{ fill: "blue", }} />
              Popup Today: {dayTot}
              <br />
              <PeopleIcon style={{ fill: "blue", }} />
              Popup this week: {weekTot}
            </Link>
            <br />
          </>
        )}

        <Grid container>
          <Grid item xs={4}>{siteInfo["ALG"] === "NA" ? null : (
            <>
              <Link
                className="nsLink"
                to={{
                  pathname: "/ALGSummary",
                  state: {
                    Keyword: siteInfo["siteName"],
                  },
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                  <span><SiteLink type="" status={siteInfo["ALG"]} /></span> <span style={{ fontSize: 20, fontWeight: "bold" }}>Algorithms</span>
                </div>
              </Link>
              <Grid container>
                <Grid item xs={4}>
                  Version
                </Grid>
                <Grid item xs={6}>
                  {siteInfo["ALGVer"]}
                </Grid>
                <Grid item xs={4}>
                  Last Run
                </Grid>
                <Grid item xs={6}>
                  {utcToAEST(siteInfo["ALGLastLog"])}
                </Grid>
                <Grid item xs={4}>
                  Last successful run
                </Grid>
                <Grid item xs={6}>
                  {utcToAEST(siteInfo["ALGLastLogSuccess"])}
                </Grid>
              </Grid>
              <div style={{ paddingBottom: "1REM" }}>
                Last successful results
              </div>
              <div style={{ width: "95%", height: "25vh", overflow: "auto" }}>
                <Table aria-label="simple table" style={{ width: "95%" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Algorithm</TableCell>
                      <TableCell align="right">Count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {siteInfo["ALGTestIDs"].map((testID_total, index) => {
                      if (props.ALGTestsInfo[testID_total[0]] != undefined) {
                        return (
                          <TableRow key={index}>
                            <TableCell align="left">{props.ALGTestsInfo[testID_total[0]]}</TableCell>
                            <TableCell align="right">{testID_total[1]}</TableCell>
                          </TableRow>
                        );
                      }
                    })}
                  </TableBody>
                </Table>
              </div>
            </>
          )}
          </Grid>
          <Grid item xs={4}>{siteInfo["API"] === "NA" ? null : (
            <>
              <Link
                className="nsLink"
                to={{
                  pathname: "/APISummary",
                  state: {
                    Keyword: siteInfo["siteName"],
                  },
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                  <span><SiteLink type="" status={siteInfo["API"]} /></span> <span style={{ fontSize: 20, fontWeight: "bold" }}>API</span>
                </div>
              </Link>
              <Grid container>
                <Grid item xs={4}>
                  Version
                </Grid>
                <Grid item xs={6}>
                  {siteInfo["APIVer"]}
                </Grid>
                <Grid item xs={4}>
                  Last Extracted
                </Grid>
                <Grid item xs={6}>
                  {utcToLocal(siteInfo["APILastLog"])}
                </Grid>
              </Grid>
            </>
          )}
            {siteInfo["machLog"] === "NA" ? null : (
              <>
                <Link
                  className="nsLink"
                  to={{
                    pathname: "/machinelogs",
                    state: {
                      Keyword: siteInfo["siteName"]
                    },
                  }}
                >
                  <SiteLink type="FHT machine logs" status={siteInfo["machLog"]} />
                  <br />
                  FHT machine logs
                  <br />
                </Link>
              </>
            )}
            {siteInfo["machNew"] === "NA" ? null : (
              <>
                <Link
                  className="nsLink"
                  to={{
                    pathname: "/NewMachinesOnSite",
                    state: {
                      Keyword: siteInfo["siteName"]
                    },
                  }}
                >
                  <SiteLink type="New Machines" status={siteInfo["machNew"]} />
                  <Grid container>
                    <Grid item xs={4}>
                      New Machines
                    </Grid>
                    <Grid item xs={6}>
                      {newMachCount}
                    </Grid>
                  </Grid>
                </Link>
              </>
            )}
            {siteInfo["machSite"] === "NA" ? null : (
              <>
                <Link
                  className="nsLink"
                  to={{
                    pathname: "/MachinesOnSite",
                    state: {
                      Keyword: siteInfo["siteName"]
                    },
                  }}
                >
                  <SiteLink type="New Machines" status={siteInfo["machSite"]} />
                  <Grid container>
                    <Grid item xs={4}>
                      Machines on Site
                    </Grid>
                    <Grid item xs={6}>
                      {machSitecount}
                    </Grid>
                  </Grid>
                </Link>
              </>
            )}
            {siteInfo["logSite"] === "NA" ? null : (
              <>
                <Link
                  className="nsLink"
                  to={{
                    pathname: "/FhtLogsSelections",
                    state: {
                      Keyword: siteInfo["siteName"]
                    },
                  }}
                >
                  <SiteLink type="Latest FHT Log" status={siteInfo["logSite"]} />
                  <Grid container>
                    <Grid item xs={4}>
                      Latest FHT Log date
                    </Grid>
                    <Grid item xs={6}>
                      {logDate}
                    </Grid>
                    <Grid item xs={4}>
                      FHT Log status
                    </Grid>
                    <Grid item xs={6}>
                      {statusLog}
                    </Grid>
                  </Grid>
                </Link>
              </>
            )}
          </Grid>
          <Grid item xs={4}>
            <span style={{ fontSize: 20, fontWeight: "bold", paddingBottom: "1REM" }}>Shutdown logs</span>
            {renderShutdownLogHistory()}
          </Grid>
        </Grid>
      </DialogContent>
    );
  }

  function formatSiteDetail(stringToFormat) {
    return (
      <span style={{ fontSize: 18 }}>{stringToFormat}</span>
    )
  }

  function SiteMetaData() {
    return (
      <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <h2>{" " + siteInfo.siteName}</h2>
        </div>
        <Grid container>
          <Grid xs={4} item>
            {siteContacts == null ? null : (
              <div style={{ height: "18REM", overflow: "auto" }}>
                <div>
                  {/*** Champion ***/}
                  <div>
                    <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Grid xs={4} item>
                        <span style={{ fontSize: 20, fontWeight: "bold" }}>Contact details</span>
                      </Grid>
                      <Grid xs={8} item>
                        {formatSiteDetail(siteContacts.fhtsitedetailsChampionName == null ? "X" : siteContacts.fhtsitedetailsChampionName)}
                      </Grid>
                    </Grid>
                  </div>
                  <Grid container>
                    <Grid xs={3} item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <PhoneIphoneIcon style={{ fill: "green" }} />
                      {formatSiteDetail(siteContacts.fhtsitedetailsChampionPhone == null ? "X" : siteContacts.fhtsitedetailsChampionPhone)}
                    </Grid>
                    <Grid xs={9} item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <EmailIcon style={{ fill: "green" }} />
                      {formatSiteDetail(siteContacts.fhtsitedetailsChampionEmail == null ? "X" : siteContacts.fhtsitedetailsChampionEmail)}
                    </Grid>
                  </Grid>
                  <br/>
                  <div>
                    <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Grid xs={4} item>
                        <span style={{ fontSize: 20, fontWeight: "bold" }}> IT Provider </span>
                      </Grid>
                      <Grid xs={8} item>
                        {formatSiteDetail(siteContacts.fhtsitedetailsITproviderOrgname == null ? "X" : siteContacts.fhtsitedetailsITproviderOrgname)}
                      </Grid>
                    </Grid>
                  </div>
                  <Grid container>
                    <Grid xs={2} item>
                      <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }} >
                        <PersonIcon style={{ fill: "green" }} />
                        {formatSiteDetail(siteContacts.fhtsitedetailsITproviderContact == null ? "X" : siteContacts.fhtsitedetailsITproviderContact)}
                      </div>
                    </Grid>
                    <Grid xs={3} item>
                      <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }} >
                        <PhoneIphoneIcon style={{ fill: "green" }} />
                        {formatSiteDetail(siteContacts.fhtsitedetailsITproviderPhone == null ? "X" : siteContacts.fhtsitedetailsITproviderPhone)}
                      </div>
                    </Grid>
                    <Grid xs={7} item>
                      <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }} >
                        <EmailIcon style={{ fill: "green" }} />
                        {formatSiteDetail(siteContacts.fhtsitedetailsITproviderEmail == null ? "X" : siteContacts.fhtsitedetailsITproviderEmail)}
                      </div>
                    </Grid>
                  </Grid>
                  <br/>
                  <span style={{ fontSize: 20, fontWeight: "bold" }}>Additional Information</span>
                  <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid xs={4} item>
                      <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }} >
                        <PersonIcon style={{ fill: "green", }} />
                        {formatSiteDetail("PROD : ")}
                        {siteContacts.fhtsitedetailsPROD == 1 ?
                          (<CheckBoxIcon style={{ fill: "green", }} />) :
                          (<CheckBoxOutlineBlankIcon style={{ fill: "green", }} />)}
                      </div>
                    </Grid>
                    <Grid xs={4} item>
                      <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }} >
                        <PersonIcon style={{ fill: "green", }} />
                        {formatSiteDetail("Practice Manager: " + (siteContacts.fhtsitedetailsPracticeManagerName == null ? "X" : siteContacts.fhtsitedetailsPracticeManagerName))}
                      </div>
                    </Grid>
                    <Grid xs={4} item>
                      <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }} >
                        <GroupworkIcon style={{ fill: "green" }} />
                        {formatSiteDetail("Trial group: " + (siteContacts.fhtsitedetailsTrialGroup == null ? "X" : siteContacts.fhtsitedetailsTrialGroup))}
                      </div>
                    </Grid>
                    <Grid xs={4} item>
                      <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }} >
                        <SettingsIcon style={{ fill: "green" }} />
                        {formatSiteDetail("IT setup: " + (siteContacts.fhtsitedetailsSiteITSetup == null ? "X" : siteContacts.fhtsitedetailsSiteITSetup))}
                      </div>
                    </Grid>
                    <Grid xs={4} item>
                      <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }} >
                        <ComputerIcon style={{ fill: "green" }} />
                        {formatSiteDetail("Server: " + (serverMachineName == null ? "X" : serverMachineName))}
                      </div>
                    </Grid>
                    <Grid xs={4} item>
                      <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }} >
                        <StorageIcon style={{ fill: "green" }} />
                        {formatSiteDetail("Patron Site URL: " + (siteContacts.fhtsitedetailsPatronSiteUrl == null ? "X" : siteContacts.fhtsitedetailsPatronSiteUrl))}
                      </div>
                    </Grid>
                    <Grid xs={12} item>
                      <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }} >
                        <LocalHospitalIcon style={{ fill: "green" }} />
                        {formatSiteDetail("EMR DB Machine: " + (siteContacts.fhtsitedetailsEMRDBMachine == null ? "X" : siteContacts.fhtsitedetailsEMRDBMachine))}
                      </div>
                    </Grid>
                    <Grid xs={12} item>
                      <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }} >
                        <CloudIcon style={{ fill: "green" }} />
                        {formatSiteDetail("EMR name: " + (siteEMR == null ? "X" : siteEMR))}
                      </div>
                    </Grid>
                    <Grid xs={12} item>
                      <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }} >
                        <LinkIcon style={{ fill: "green" }} />
                        {formatSiteDetail("Site URL: " + (siteURL == null ? "X" : siteURL))}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </Grid>
          <Grid xs={4} item>
            {<div className="SiteDetailDialog" style={{ float: "right" }} >
              Relationship support summary:
              <textarea style={{ minHeight: '180px', minWidth: "100%", float: "right" }} name="tech_support" value={supPost} onChange={handleRelChange} />
              <button type="submit" style={{ float: "right" }} onClick={handleSubmit} color="primary">Submit</button>
            </div>
            }
          </Grid>
          <Grid xs={4} item>
            {<div className="SiteDetailDialog" style={{ float: "right" }} >
              Technical support summary:
              <textarea style={{ minHeight: '180px', minWidth: "100%", float: "right" }} name="tech_support" value={techsupPost} onChange={handleTechChange} />
              <button type="submit" style={{ float: "right" }} onClick={handleTechSubmit} color="primary">Submit</button>
            </div>
            }
          </Grid>
        </Grid>


        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={renderSnackbar}
          onClose={() => { setSnackbar(false) }}
        >
          <SnackbarContent style={{
            backgroundColor: snackbarMessageColor,
          }}
            message={supMes}
          />
        </Snackbar>
      </DialogTitle>
    );
  }
}