import React, { Component } from "react";

import Grid from "@material-ui/core/Grid";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from "@material-ui/core/FormControl";
import { Typography } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Configs from "../../Service/Configuration";
import FetchApi from "../../Service/FetchApi";
import Button from "@material-ui/core/Button";

class SelectedRecChkDisplayConfigurations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disableCheckbox: false,
    }
  }
  diseaseName = {
    //Site Algo
    1: "Chronic Kidney Disease",
    2: "Cardiovascular Disease",
    3: "Diabetes",
    4: "Prostate Cancer",
    5: "Platelet Cancer",
    6: "Gastrointestinal/Endometrial Cancer",
    7: "Mental health",
    8: "Covid",
    10: "TOR Test Algorithm",
    11: "IC3 Trial",
    13: "Boehringer",
    14: "Wiser AD",
    17: "REGENT-1",
    19: "HARMON-E",
    28: "Western health zeus trial",
    29: "Boehringer Synchronize trial",
    30: "Novonordisk NN9541",
    uncategorized: [0, 9, 100],
  }

  componentDidMount() {
    // Condition to disableCheckbox dependent on account role and site type.
    if (parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandE) {
      this.setState({
        disableCheckbox: true
      })
    } else if (Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType != Configs.supportSiteType.torchSupport
      && Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType != Configs.supportSiteType.torchTestSupport
      && Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType != Configs.supportSiteType.fhtTestSupport
      && Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType != Configs.supportSiteType.fhtSupport
      ) {
        if (parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator) {
          this.setState({
            disableCheckbox: true
          })
        }
    } else if (Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchSupport
      || Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchTestSupport
      || Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType != Configs.supportSiteType.fhtTestSupport
      || Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType != Configs.supportSiteType.fhtSupport) {
        if (parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator
        && (window.location.pathname == "/configViewAccountRecommendation"
            || window.location.pathname == "/configViewDisplayRecommendation")) {
          this.setState({
            disableCheckbox: true
          })
        }
    }
  }
  
  handleSiteAlgoMapCheckBoxChange = (checked, algoName, diseaseId) => {
    var selectedAlgos = JSON.parse(JSON.stringify(this.props.selectedAlgos))
    this.setState({
      currentlySelectedDiseaseID: diseaseId
    })

    if (checked) {
      // add
      if (!(selectedAlgos.includes(algoName))) selectedAlgos.push(algoName);
    } else {
      // delete
      if (selectedAlgos.includes(algoName)) {
        var index = selectedAlgos.indexOf(algoName)
        selectedAlgos.splice(index, 1);
      }
    }
    this.props.selectedAlgoritmsParentFunction(selectedAlgos)
  }

  render() {    
    var checkedBoxToRender = this.props.selectedAlgos
    if (this.props.AlgoConfigMap == undefined) {
      return (
        <div>Loading</div>
      )
    } else {
      return (
        <div>
          {Object.entries(this.props.AlgoConfigMap).map((algoByDisease, index) => {
            // Template for each box of disease and their algos
            var diseaseId = algoByDisease[0];
            var numberOfAlgsInDisease = algoByDisease[1].length;
  
            // Copied and pasted rendering due to grid formatting.
            if (numberOfAlgsInDisease <= 3) {
              return (
                <Grid key={diseaseId} item xs={12} container style={{ padding: "10px" }}>
                  <Box
                    borderColor="primary.main"
                    bgcolor="white"
                    border={2}
                    width={1}
                  >
                    <div style={{ textAlign: "center", color: "white" }}>
                      {/** Title box for diseaseName **/}
                      <Box bgcolor="primary.main">
                        <Typography variant="h6">
                          {
                            (diseaseId in this.diseaseName) ?
                              (this.diseaseName[diseaseId]) : ("Uncategorized")
                          }
                        </Typography>
                      </Box>
                    </div>
                    <Grid container justify="center">
                      <FormControl component="fieldset" >
                        <FormGroup>
                          <Grid container style={{ padding: "2REM" }}>
                            {algoByDisease[1].map((algo) => {
                              // Render algo label if an algo is not active in all selected sites.
                              var renderedAlgoLabel = null;
                              if (this.props.uniqueActivatedAlgos.includes(algo) && this.props.selectedSites.length > 1) {
                                renderedAlgoLabel = <p style={{ maxWidth: "10REM", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "100%", background: "orange" }}>{algo}</p>
                              }
                              else {
                                renderedAlgoLabel = <p style={{ maxWidth: "10REM", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "100%" }}>{algo}</p>
                              }
      
                              return (
                                <Grid key={algo} item xs>
                                  <FormControlLabel
                                    style={{ display: "table" }}
                                    control={
                                      <div style={{ display: "table-cell" }}>
                                        <Checkbox
                                          disabled = {this.state.disableCheckbox}
                                          checked={checkedBoxToRender.includes(algo)}
                                          onChange={(event) => {
                                            this.handleSiteAlgoMapCheckBoxChange(event.target.checked, algo, diseaseId)
                                          }}
                                        /></div>}
                                    label={
                                      renderedAlgoLabel
                                    }
                                  />
                                </Grid>
                              )
                            })}
                          </Grid>
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Box>
                </Grid>
              );
            } else {
              return (
                <Grid key={diseaseId} item xs={12} container style={{ padding: "10px" }}>
                  <Box
                    borderColor="primary.main"
                    bgcolor="white"
                    border={2}
                    width={1}
                  >
                    <div style={{ textAlign: "center", color: "white" }}>
                      {/** Title box for diseaseName **/}
                      <Box bgcolor="primary.main">
                        <Typography variant="h6">
                          {
                            (diseaseId in this.diseaseName) ?
                              (this.diseaseName[diseaseId]) : ("Uncategorized")
                          }
                        </Typography>
                      </Box>
                    </div>
                    <Grid container justify="center">
                      <FormControl component="fieldset">
                        <FormGroup>
                          <Grid container style={{ padding: "2REM" }}>
                            {algoByDisease[1].map((algo) => {
                              // Render algo label if an algo is not active in all selected sites.
                              var renderedAlgoLabel = null;
                              if (this.props.uniqueActivatedAlgos.includes(algo) && this.props.selectedSites.length > 1) {
                                renderedAlgoLabel = <p style={{ maxWidth: "10REM", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "100%", background: "orange" }}>{algo}</p>
                              }
                              else {
                                renderedAlgoLabel = <p style={{ maxWidth: "10REM", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "100%" }}>{algo}</p>
                              }
      
                              return (
                                <Grid key={algo} item xs={4}>
                                  <FormControlLabel
                                    style={{ display: "table" }}
                                    control={
                                      <div style={{ display: "table-cell" }}>
                                        <Checkbox
                                          disabled = {this.state.disableCheckbox}
                                          checked={checkedBoxToRender.includes(algo)}
                                          onChange={(event) => {
                                            this.handleSiteAlgoMapCheckBoxChange(event.target.checked, algo, diseaseId)
                                          }}
                                        /></div>}
                                    label={
                                      renderedAlgoLabel
                                    }
                                  />
                                </Grid>
                              )
                            })}
                          </Grid>
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Box>
                </Grid>
              );
            }
          })}
        </div>
      );
    }
  }
}
export default SelectedRecChkDisplayConfigurations;
