import React from "react";
// import logo from "./logo.svg";
import "./App.css";
import Login from "./Component/Login";
import Configs from "./Service/Configuration";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Dashboard from "./View/Dashboard";
import POCSummary from "./View/POCSummary";
// import SC4CSummary from "./View/SC4CSummary";
import APISummary from "./View/APISummary";
import ALGSummary from "./View/GeneralPages/ALGSummary/ALGSummary";
import Navigator from "./Component/Navigator";
import TorchNavigator from "./Component/TorchNavigator";
import Release from "./View/Release";
import Report from "./View/Report";
import MachineLogs from "./View/MachineLogs"
// import { render } from "@testing-library/react";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarComponent from "./Component/SnackbarComponent";
import FetchApi from "./Service/FetchApi";
import ALGSummaryHistory from "./View/GeneralPages/ALGSummary/ALGSummaryHistory";
import NewMachinesOnSite from "./View/NewMachinesOnSite";
import FHTlogs from "./View/FHTLogs";
import MachinesOnSite from "./View/MachinesOnSite";
import CSVDownload from "./View/CSVdownload"
import MyAccount from "./View/MyAccount";
import FHTLogsDataGrid from "./View/FHTLogsDataGrid";
import FHTLogsSelections from "./View/FHTLogsSelections";
import POCHeartBeatLastWeek from "./View/POCHeartBeatLastWeek";
import TorchTrialStatusSummary from "./View/TorchPages/TorchTrialStatusSummary";
import MapClinicUserToCohorts from "./View/TorchPages/LimitedTorchUser/MapClinicUserToCohorts";
import MapClinicUserToCohortsGrid from "./View/TorchPages/LimitedTorchUser/MapClinicUserToCohortsGrid";
import SoftwareMapping from "./View/GeneralPages/SoftwareMapping";
import SiteRegister from "./View/GeneralPages/SiteRegister";
import SiteTypes from "./View/GeneralPages/SiteTypes";
import SoftwareRegister from "./View/GeneralPages/SoftwareRegister";
import Accounts from "./View/GeneralPages/Accounts";
import AccountCreation from "./View/GeneralPages/AccountCreation";
import ResetPassword from "./View/GeneralPages/ResetPassword";
import GenerateInstallCommand from "./View/GeneralPages/GenerateInstallCommand";
import SiteGroupings from "./View/GeneralPages/SiteGroupings";
//import GeneralConfigurationEdit from "./View/GeneralPages/GeneralConfigurationEdit";
import UserManagement from "./View/GeneralPages/UserManagement";
import EventViewerLogAndTaskSchedulerLog from "./View/EventViewerLogAndTaskSchedulerLog";
import AdminDBActions from "./View/GeneralPages/AdminDBActions";
import StaffExtractDuplicateIssue from "./View/GeneralPages/StaffExtractDuplicateIssue";
import TorchManageLimitedUsers from "./View/TorchManageLimitedUsers";
import ConfigSiteRecChkEdit from "./View/GeneralPages/ConfigSiteRecChkEdit";
import ConfigSiteDisplayRecEdit from "./View/GeneralPages/ConfigSiteDisplayRecEdit";
import ConfigSiteAccountModuleEdit from "./View/GeneralPages/ConfigSiteAccountModuleEdit";
import ConfigSitePOCModuleEdit from "./View/GeneralPages/ConfigSitePOCModuleEdit";
import TorchRemoteCohortCreation from "./View/TorchPages/TorchRemoteCohortCreation";
import TorchRemoteCohortMonitoring from "./View/TorchPages/TorchRemoteCohortMonitoring";
import FHTRemoteCohortCreation from "./View/FHTPages/FHTRemoteCohortCreation";
import FHTRemoteCohortMonitoring from "./View/FHTPages/FHTRemoteCohortMonitoring";
import SiteMachineDeactivation from "./View/GeneralPages/SiteMachineDeactivation";
import AlgorithmStatusTable from "./View/GeneralPages/AlgorithmStatusTable/AlgorithmStatusTable";
import ManageAlgorithmClassNames from "./View/GeneralPages/ManageAlgorithmClassNames";
import RestartServices from "./View/RestartServices";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.existingToken = localStorage.getItem(Configs.tokenInfo);
    this.state = {
      isDrawerOpen: false,
      loggedIn: false,
      openSnackbar: false,
      snackbarVariant: "",
      snackbarMessage: "",
      loading: true,
    };
    // console.log(this.existingToken);
    if (this.existingToken !== null && this.existingToken !== undefined) {
      this.authenticateExistingToken(this.existingToken);
    }    
  }
  // this.CallbackLoginDialogSuccess = this.CallbackLoginDialogSuccess.bind(this);

  componentDidMount() {
    var currentSiteType = Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType;
    if (currentSiteType  == Configs.supportSiteType.torchSupport || currentSiteType == Configs.supportSiteType.torchTestSupport)
      document.title = 'TorchRecruit_Report'
    else if (currentSiteType  == Configs.supportSiteType.SC4CSupport)
      document.title = 'SC4C_Report'
    else
      document.title = 'FHT_Report'
  }

  CallbackLoginDialogSuccess = (e) => {
    console.log(e);
    this.setState({
      loggedIn: true,
      loading: false,
      user: e,
    });
  };

  GlobalFunctionDisplaySnackbarMessage = (msg, variant) => {
    this.setState({
      snackbarMessage: msg,
      snackbarVariant: variant,
      openSnackbar: true,
    });
  };

  SignOut = () => {
    this.setState({ loggedIn: false });
    localStorage.removeItem(Configs.tokenInfo);
  };

  authenticateExistingToken = (token) => {
    const api = FetchApi(Configs.api.checkValidTokenLink);
    api
      .get()
      .then((response) => {
        console.log("authenticateExistingToken no error")
        if (response.status == 401) { 
          throw "Please relogin.";
        } else {
          this.setState({
            loggedIn: true,
            loading: false,
            user: response.userName,
          });
        }
      })
      .catch((err) => {
        console.log("authenticateExistingToken error")
        this.setState({
          loggedIn: false,
          loading: false,
          user: "",
        });
        // this.setState({loading: false});
      });
  };

  toggleDrawer = () => {
    this.setState({
      isDrawerOpen: !this.state.isDrawerOpen,
    });
  };

  render() {
    return (
      <div className = "mainPageBackground">
        <Router>
          <div>
            {(Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.fhtSupport
            || Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.fhtTestSupport
            || Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.SC4CSupport) && (
              <Navigator
              toggleDrawer={this.toggleDrawer}
              loggedIn={this.state.loggedIn}
              CallbackSignout={this.SignOut}
              user={this.state.user}
            ></Navigator>
            )}

            {(Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchSupport
            || Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchTestSupport) && (
              <TorchNavigator
              toggleDrawer={this.toggleDrawer}
              loggedIn={this.state.loggedIn}
              CallbackSignout={this.SignOut}
              user={this.state.user}
            ></TorchNavigator>
            )}


            {this.state.loggedIn && (
              <Switch>
                {parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin && (
                  <Route path="/release">
                    <Release
                      GlobalFunctionDisplaySnackbarMessage={
                        this.GlobalFunctionDisplaySnackbarMessage
                      }
                    />
                  </Route>
                )}
                <Route path="/report">
                  <Report />
                </Route>

                <Route path="/mapusertocohort">
                  <MapClinicUserToCohorts
                  GlobalFunctionDisplaySnackbarMessage={
                    this.GlobalFunctionDisplaySnackbarMessage
                  }
                  user={this.state.user}
                  />
                </Route>
                <Route path="/mapusertocohortgrid">
                  <MapClinicUserToCohortsGrid
                  GlobalFunctionDisplaySnackbarMessage={
                    this.GlobalFunctionDisplaySnackbarMessage
                  }
                  user={this.state.user}
                  />
                </Route>
                <Route path="/AlgorithmStatusTable">
                  <AlgorithmStatusTable
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                  />
                </Route>

                <Route
                  path="/POCSummary/version/:Number"
                  component={POCSummary}
                />
                <Route
                  path="/SC4CSummary/version/:Number"
                  component={POCSummary}
                />
                <Route
                  path="/APISummary/version/:Number"
                  component={APISummary}
                />
                <Route
                  path="/ALGSummary/version/:Status"
                  component={ALGSummary}
                />
               <Route
                  path="/machinelogs/:Number"
                  component={MachineLogs}
                />
                <Route
                  path="/ALGSummaryHistory/version/:Status"
                  component={ALGSummaryHistory}
                />

                <Route path="/POCSummary/status/:Status" component={POCSummary} />
                <Route path="/SC4CSummary/status/:Status" component={POCSummary} />
                <Route path="/APISummary/status/:Status" component={APISummary} />
                <Route path="/ALGSummary/status/:Status" component={ALGSummary} />
                <Route path="/ALGSummaryHistory/status/:Status" component={ALGSummaryHistory} />
                <Route path="/POCSummary" component={POCSummary} />
                <Route path="/SC4CSummary" component={POCSummary} />
                <Route path="/APISummary" component={APISummary} />
                <Route path="/ALGSummary" component={ALGSummary} />
                <Route path="/machinelogs" component={MachineLogs} />
                <Route path="/ALGSummaryHistory" component={ALGSummaryHistory} />
                <Route path="/NewMachinesOnSite" component={NewMachinesOnSite} />
                <Route path="/fhtlogs" component={FHTlogs} />
                <Route path="/FhtLogsSelections" component = {FHTLogsSelections}/>
                <Route path="/fhtlogsdatagrid" component={FHTLogsDataGrid} />
                <Route path="/MachinesOnSite" component={MachinesOnSite} />
                <Route path="/POCHeartBeatLastWeek" component={POCHeartBeatLastWeek} />
                                       
                <Route path="/CSVDownload">
                  <CSVDownload
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                  />
                </Route>

                <Route path="/MyAccount">
                  <MyAccount
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                  />
                </Route>
               
                {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                <Route path="/configNewMachines">
                  <NewMachinesOnSite
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                
                {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                <Route path="/configSoftwareMapping">
                  <SoftwareMapping
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                
                {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                <Route path="/configSiteRegister">
                  <SiteRegister
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                
                {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                <Route path="/configSiteType">
                  <SiteTypes
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                
                {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                <Route path="/configSoftwareRegister">
                  <SoftwareRegister
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                
                {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                <Route path="/configMachineOnSite">
                  <MachinesOnSite
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                
                {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                <Route path="/configAccounts">
                  <Accounts
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                
                {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                <Route path="/configCreateAccount">
                  <AccountCreation
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                
                {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                <Route path="/configResetPassword">
                  <ResetPassword
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                
                {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                <Route path="/configInstallCommandEncryptDecrypt">
                  <GenerateInstallCommand
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                
                {((parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin
                  || parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator)) && (
                <Route path="/configCreateSiteGroupings">
                  <SiteGroupings
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                
                {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                <Route path="/configEditAccountCheckALGConfig">
                  <ConfigSiteRecChkEdit
                    configType = {Configs.configurationEditingPageTypes.accountChkAlgConfig}
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                
                {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                <Route path="/configEditAccountRecommendationALGConfig">
                  <ConfigSiteRecChkEdit
                    configType = {Configs.configurationEditingPageTypes.accountRecAlgConfig}
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                
                {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                <Route path="/configEditAccountModuleConfig">
                  <ConfigSiteAccountModuleEdit
                    configType = {Configs.configurationEditingPageTypes.siteModulesConfig}
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                
                {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                <Route path="/configEditAccountPOCConfig">
                  <ConfigSitePOCModuleEdit
                    configType = {Configs.configurationEditingPageTypes.pocModuleConfig}
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}
                {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                <Route path="/configEditDisplayRecommendationConfig">
                  <ConfigSiteDisplayRecEdit
                    configType = {Configs.configurationEditingPageTypes.accountDisplayAlgConfig}
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}
                {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                <Route path="/configSupportUserManagement">
                  <UserManagement
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                
                {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                <Route path="/configEventViewerTaskScheduler">
                  <EventViewerLogAndTaskSchedulerLog
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}
                {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                <Route path="/configRestartServices">
                  <RestartServices
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}

                {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                <Route path="/configAdminDBActions">
                  <AdminDBActions
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                
                {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                <Route path="/configStaffExtractDuplicate">
                  <StaffExtractDuplicateIssue
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                
                {(Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType !== Configs.supportSiteType.SC4CSupport
                  && Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType !== Configs.supportSiteType.fhtSupport
                  && Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType !== Configs.supportSiteType.fhtTestSupport
                  && (parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin
                  || parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator)) && (
                <Route path="/configRemoteCreateCohort">
                  <TorchRemoteCohortCreation
                    configType = {Configs.configurationEditingPageTypes.remoteCreateCohorts}
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}  
                {(Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType !== Configs.supportSiteType.SC4CSupport
                  && Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType !== Configs.supportSiteType.torchSupport
                  && Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType !== Configs.supportSiteType.torchTestSupport
                  && (parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin
                  || parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator)) && (
                <Route path="/configFHTRemoteCreateCohort">
                  <FHTRemoteCohortCreation
                    configType = {Configs.configurationEditingPageTypes.remoteCreateCohorts}
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                   
                {(Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType !== Configs.supportSiteType.SC4CSupport
                  && Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType !== Configs.supportSiteType.fhtSupport
                  && Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType !== Configs.supportSiteType.fhtTestSupport)
                  && (parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin
                  || parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator) && (
                <Route path="/configCohortCreateMonitoring">
                  <TorchRemoteCohortMonitoring
                    configType = {Configs.configurationEditingPageTypes.createCohortMonitoring}
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                
                {(Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType !== Configs.supportSiteType.SC4CSupport
                  && Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType !== Configs.supportSiteType.torchSupport
                  && Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType !== Configs.supportSiteType.torchTestSupport)
                  && (parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin
                  || parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator) && (
                <Route path="/configFHTCohortCreateMonitoring">
                  <FHTRemoteCohortMonitoring
                    configType = {Configs.configurationEditingPageTypes.createCohortMonitoring}
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}        
                {(Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType !== Configs.supportSiteType.SC4CSupport
                  && (parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin
                      || parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator)) && (
                <Route path="/configLimitedTorchUsers">
                  <MapClinicUserToCohortsGrid
                    // Config type might not be needed for this component
                    configType = {Configs.configurationEditingPageTypes.createCohortMonitoring}
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                
                {(Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType !== Configs.supportSiteType.SC4CSupport
                  && (parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin
                      || parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportIandECoordinator)) && (
                <Route path="/configManageLimitedTorchUsers">
                  <TorchManageLimitedUsers
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                
                {(Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType !== Configs.supportSiteType.SC4CSupport) && (
                <Route path="/configViewAccountRecommendation">
                  <ConfigSiteRecChkEdit
                    configType = {Configs.configurationEditingPageTypes.accountRecAlgConfig}
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}                
                {(Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType !== Configs.supportSiteType.SC4CSupport) && (
                <Route path="/configViewDisplayRecommendation">
                  <ConfigSiteDisplayRecEdit
                    configType = {Configs.configurationEditingPageTypes.accountDisplayAlgConfig}
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                    user={this.state.user}
                  />
                </Route>
                )}      
              
              {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                  <Route path="/manageAlgorithmClassNames">
                    <ManageAlgorithmClassNames
                      GlobalFunctionDisplaySnackbarMessage={
                        this.GlobalFunctionDisplaySnackbarMessage
                      }
                    />
                  </Route>
                )} 
                
                {/* Torch specific routes */}
                {(Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchSupport ||
                  Configs.SiteConfigurationMapping[window.location.hostname].currentSiteType == Configs.supportSiteType.torchTestSupport) && (           
                  <Route path="/TorchTrialStatusSummary">
                  <TorchTrialStatusSummary
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                  />
                  </Route>
                )}

                {(parseInt(localStorage.userRole) === Configs.fhtSupportRoleType.fhtSupportAdmin) && (
                  <Route path="/SiteMachineDeactivation">
                    <SiteMachineDeactivation
                      GlobalFunctionDisplaySnackbarMessage={
                        this.GlobalFunctionDisplaySnackbarMessage
                      }
                    />
                  </Route>
                )}

                <Route path="/">
                  <Dashboard
                    isDrawerOpen={this.state.isDrawerOpen}
                    toggleDrawer={this.toggleDrawer}
                    username={this.state.user}
                    GlobalFunctionDisplaySnackbarMessage={
                      this.GlobalFunctionDisplaySnackbarMessage
                    }
                  />
                </Route>
              </Switch>
            )}
          </div>
        </Router>

        {this.state.loggedIn === false && (
          <Login
            CallbackLoginDialogSuccess={this.CallbackLoginDialogSuccess}
            GlobalFunctionDisplaySnackbarMessage={
              this.GlobalFunctionDisplaySnackbarMessage
            }
          ></Login>
        )}

        {this.state.openSnackbar && (
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={this.state.openSnackbar}
            autoHideDuration={4000}
            onClose={() => this.setState({ openSnackbar: false })}
          >
            <SnackbarComponent
              onClose={() => this.setState({ openSnackbar: false })}
              variant={this.state.snackbarVariant}
              message={this.state.snackbarMessage}
            />
          </Snackbar>
        )}
      </div>
    );
  }
}

export default App;
