// Copied and pasted from FHTHome, removing some cards that are specific to FHT.

import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import TorchSummarySiteOverview from "./TorchDashboard/TorchSummarySiteOverview";
import TorchSummaryStatisticCard from "./TorchDashboard/TorchSummaryStatisticCard";
import TorchSummarySoftwareStatus from "./TorchDashboard/TorchSummarySoftwareStatus";
import CircularProgress from "@material-ui/core/CircularProgress";
import Configs from "../../Service/Configuration";
import FetchApi from "../../Service/FetchApi";
import {
  utcToAESTLocal,
  changeGraphDataDateToAEST
} from "../HelperFunctions";
import SelectSiteGroupingsSelectionOnly from "../SharedComponents/SelectSiteGroupingsSelectionOnly";

class TorchHome extends Component {
  constructor(props) {
    super();
    this.state = {
      heartbeats: props.heartbeats,
      allTests: props.allTests,
      localVersion: props.localVersion,
      serverSummary: props.serverSummary,
      latestFhtLog: props.latestFhtLog,
      serverHistory: [],
      numDayUsers: 0,
      numWeekUsers: 0,
      numUniqueUsers: 0,
      POCActiveNum: 0,
      POCActive: [],
      isLoaded: false,
      numActiveALG: 0,
      numALGRecords: 0,
      loadingMessage: "Setting up FHT...",
      algorithmSummaryGraphDataList: [],
      apiSummaryGraphDataList: [],
      siteOverviewSummaryGraphDataList: [],
      siteOverviewUptimeData: [],

      selectedSiteGroupings: 0,
      selectedSiteIDs: [],
      isFetchingData: true,
    };
    this.SharedComponentSiteGroupingCallback = this.SharedComponentSiteGroupingCallback.bind(this);
    this.SharedComponentSelectedSiteGroupingIDCallback = this.SharedComponentSelectedSiteGroupingIDCallback.bind(this);
  }
  SharedComponentSiteGroupingCallback(siteGroupingsSiteIDs) {
    this.setState({
      selectedSiteIDs: siteGroupingsSiteIDs
    })
    this.getAllDashboardData();
  }

  SharedComponentSelectedSiteGroupingIDCallback(siteGroupingID) {
    this.setState({
      selectedSiteGroupings: siteGroupingID
    })
  }

  getPOCSummary = () => {
    // Note that FHTInfo still uses the old code to determine poc
    const api = FetchApi("getPOCSummary");
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then(response => {
      //console.log(response);
      this.setState({ POCSummary: response });
    })
  }

  getPOCDeactiveNum = () => {
    const api = FetchApi(Configs.api.getNumPOCDeactivatedSites)
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
    };
    api.post(body).then(response => {
      console.log("deactiveNum", response);
      this.setState({
        numPOCDeactivatedSites: response.numDeactiveSites,
        numPOCConfigParsingIssue: response.numPOCConfigIssues,
        siteIDsWithPOCConfigIssues: response.siteIDsWithPOCConfigIssues,
        siteIDsThatAreDeactivedInPOCConfig: response.siteIDsThatAreDeactivated
      });
    })
  }

  getAllDashboardData = async () => {
    this.setState({
      isFetchingData: true,
    })
    // Query the graphing data first due to time it takes to obtain data.
    const api6 = FetchApi(Configs.api.getALGAPISiteOverviewHistoricalGraphData);
    let body = {
      SiteTypeIDs: Configs.SiteConfigurationMapping[window.location.hostname].siteTypeID,
      SelectedSiteIDs: this.state.selectedSiteIDs
    };
    await api6.post(body).then((response) => {
      console.log("getALGAPISiteOverviewHistoricalGraphData response", response)
      var reformattedALGData = changeGraphDataDateToAEST(response.algorithmSummaryGraphDataList);
      var reformattedAPIData = changeGraphDataDateToAEST(response.apiSummaryGraphDataList);
      var reformattedSiteOverviewData = changeGraphDataDateToAEST(response.siteOverviewSummaryGraphDataList);

      var siteOverviewUptimeData = response.siteOverviewGraphDataUptimeToday;
      if (siteOverviewUptimeData == null)
        siteOverviewUptimeData = {
          "Active Sites": 0,
          "Date": "N/A",
          "Inactive Site": 0
        }

      this.setState({
        algorithmSummaryGraphDataList: reformattedALGData,
        apiSummaryGraphDataList: reformattedAPIData,
        siteOverviewSummaryGraphDataList: reformattedSiteOverviewData,
        siteOverviewUptimeData: siteOverviewUptimeData
      })
    });

    const api2 = FetchApi(Configs.api.getSummaryWebAPIData);
    api2.post(body).then((response) => {
      this.setState({
        APIVer: response.apiVersionCount,
        numAPISites: response.totalSites,
        numAPIAlert: response.totalInactiveSites,
      })
    })

    const api3 = FetchApi(Configs.api.getSummaryALGData);
    api3.post(body).then((response) => {
      console.log("getSummaryALGData response", response)
      this.setState({
        ALGVer: response.algorithmVersionCount,
        numALGRecords: response.maxLocalVersionAlgList,
        numActiveALG: response.activeSitesList.length
      })
    })

    const api4 = FetchApi(Configs.api.getSiteSummaryData);
    await api4.post(body).then((response) => {
      this.setState({
        FHTInfo: response.fhtInfo,
      })
    })

    const api5 = FetchApi(Configs.api.getAlgorithmDescriptions);
    api5.post(body).then((response) => {
      this.setState({
        ALGTestsInfo: response.fhtRecommendationDescriptions,
      });
    });

    setTimeout(() => {
      console.log("this.state.FHTInfo", this.state.FHTInfo)
      this.setState({
        isLoaded: true,
        isFetchingData: false,
      })
    }, 1000);
  }
  componentDidMount = async () => {
    this.getAllDashboardData();
  };

  render() {
    const {
      numUniqueUsers,
      numDayUsers,
      numWeekUsers,
      POCActive,
      POCActiveNum,
      POCVer,
      APIVer,
      numAPISites,
      numAPIAlert,
      ALGTestsInfo,
      numActiveALG,
      numALGRecords,
      ALGVer,
      FHTInfo,
      loadingMessage,
      latestFhtLog,
      isLoaded,
      POCSummary,
    } = this.state;

    if (isLoaded && APIVer !== undefined && ALGVer !== undefined) {
      return (
        // Moving the TorchHome "up", as styling in parent components causes it to be moved down.
        <div style={{ position: "relative", top: "-4REM" }}>
          <h1
            // Required when graphs are enabled.
            style={{ paddingBottom: "2.5REM" }}
          >
            Torch Dashboard
            <SelectSiteGroupingsSelectionOnly
              selectedSiteGroupings={this.state.selectedSiteGroupings}
              SharedComponentSiteGroupingCallback={this.SharedComponentSiteGroupingCallback}
              SharedComponentSelectedSiteGroupingIDCallback={this.SharedComponentSelectedSiteGroupingIDCallback}
            />
          </h1>
          {/* Hide summary cards for now */}
          {/* <Grid container spacing={10} alignItems="center" justify="center">
            <Grid item md={3} xs={6}>
              <TorchSummaryStatisticCard
                summaryCardTitle={"Total trial sites"}
                summaryCardNumber={"85"}
                summaryCardImprovementNumber={"20"}
                summaryCardImprovementNumberColor={"+ve"}
                summaryCardIcon={"totalSites"}
                summaryCardIconBackgroundColour={"#666666"}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TorchSummaryStatisticCard
                summaryCardTitle={"Total patients"}
                summaryCardNumber={"840"}
                summaryCardImprovementNumber={"55"}
                summaryCardImprovementNumberColor={"-ve"}
                summaryCardIcon={"totalPatients"}
                summaryCardIconBackgroundColour={"#065535"}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TorchSummaryStatisticCard
                summaryCardTitle={"Active trials"}
                summaryCardNumber={"5"}
                summaryCardImprovementNumber={"0"}
                summaryCardImprovementNumberColor={"-"}
                summaryCardIcon={"activeTrials"}
                summaryCardIconBackgroundColour={"#6897bb"}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TorchSummaryStatisticCard
                summaryCardTitle={"Active recruitments"}
                summaryCardNumber={"8"}
                summaryCardImprovementNumber={"2"}
                summaryCardImprovementNumberColor={"+ve"}
                summaryCardIcon={"activeRecruitments"}
                summaryCardIconBackgroundColour={"#800000"}
              />
            </Grid>
          </Grid> */}

          {this.state.isFetchingData ?
            <div><CircularProgress />Updating dashboard</div> :
            <div>
              <Grid container spacing={10} alignItems="center" justify="center">
                <Grid item md={6} xs={12}>
                  <TorchSummarySoftwareStatus
                    softwareStatusVersionsOnSite={APIVer}
                    softwareStatusActiveNumber={numAPISites - numAPIAlert}
                    softwareStatusInactiveNumber={numAPIAlert}
                    softwareStatusTitle={"WebAPI"}
                    softwareStatusURL={"APISummary"}
                    softwareStatusGraphBackgroundColour={"#D6DDFFFF"}
                    softwareStatusWeeklyData={this.state.apiSummaryGraphDataList}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TorchSummarySoftwareStatus
                    softwareStatusVersionsOnSite={ALGVer}
                    softwareStatusActiveNumber={numActiveALG}
                    softwareStatusInactiveNumber={numALGRecords - numActiveALG}
                    softwareStatusTitle={"Algorithms"}
                    softwareStatusURL={"ALGSummaryHistory"}
                    softwareStatusGraphBackgroundColour={"#D2FFCCFF"}
                    softwareStatusWeeklyData={this.state.algorithmSummaryGraphDataList}
                  />
                </Grid>
              </Grid>

              <div style={{ paddingTop: "5REM" }}>
                <TorchSummarySiteOverview
                  siteOverviewGraphBackgroundColour={"#FFD1E8FF"}
                  siteOverviewActiveSites={this.state.siteOverviewUptimeData["Active Sites"]}
                  siteOverviewInactiveSites={this.state.siteOverviewUptimeData["Inactive Site"]}
                  type={"FHT"}
                  siteWiki={FHTInfo}
                  ALGTestsInfo={ALGTestsInfo}
                  softwareStatusWeeklyData={this.state.siteOverviewSummaryGraphDataList}
                />
              </div>
            </div>
          }
        </div>
      );
    } else {
      return (
        <div>
          <CircularProgress />
          {loadingMessage}
        </div>
      );
    }
  }
}

export default TorchHome;
